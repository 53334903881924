.b-request_info {
	&-title {
		@extend %dialog-title;
	}

	&-message {
		padding-top: 200px;
	}

	&-text {
		@include font(18px);
		display: block;
		text-align: center;
	}

	&-form {
		max-width: 350px;
		margin: 0 auto;
	}

	&-button {
		float: right;
	}
}