@include checkout-billing;

.b-checkout {
	&-billing_address {
		margin-bottom: 10px;
	}
	// Coupon Code
	&-discount_payments {
		@extend %checkout-primary-block;


		#dwfrm_billing_couponCode {
			border-right: 0;
		}
	}
	// Payment Methods
	&-payment_methods {
		@extend %checkout-primary-block;
	}

	&-privacy_policy {
		@include flexbox();
		align-items: baseline;
		margin: 20px 0 15px;
		max-width: 650px;
	}

	&-privacy_label {
		color: $color-grey;
		font-size: 12px;
		a {
			color: $color-grey;
		}
	}
}

.b-payment_method {
	&-icon {
		&:before {
			margin: -7px 20px -6px 0;
		}
	}

	&-message:not(:empty) {
		padding-left: 30px;
		padding-top: 10px;
		font-weight: 300;
	}
	&-description {
		padding-top: 5px;
		padding-left: 25px;
		font-size: 12px;
		color: #999;
	}
}

.payment-method-options {
	.f-field {
		margin-bottom: 20px;
	}
}
