/* 
 *	Owl Carousel - Animate Plugin
 */
@mixin owl-carousel-plugin {
	.owl-carousel .animated {
		-webkit-animation-duration: 1000ms;
		animation-duration: 1000ms;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	.owl-carousel .owl-animated-in {
		z-index: 0;
	}
	.owl-carousel .owl-animated-out {
		z-index: 1;
	}
	
	.fadeOut {
		@include animation(fadeOut);
	}
	
	@include keyframes(fadeOut) {
		0%   { opacity: 1; }
		100% { opacity: 0; }
	}
	
	.fadeIn {
		@include animation(fadeIn);
	}
	
	@include keyframes(fadeIn) {
		0%   { opacity: 0; }
		100% { opacity: 1; }
	}
	
	/* 
	 *   Owl Carousel - Auto Height Plugin
	 */
	.owl-height {
		@include transition(height 500ms ease-in-out)
	}
	
	/* 
	 *   Core Owl Carousel CSS File
	 */
	.owl-carousel {
		width: 100%;
		-webkit-tap-highlight-color: transparent;
		/* position relative fix webkit rendering fonts issue */
		position: relative;
		&.owl-loading {
			opacity: 0;
		}
		&.owl-hidden {
			opacity: 0;
		}
	}
	.owl-stage {
		@include clearfix;
		position: relative;
		-ms-touch-action: pan-Y;
	}
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		@include transform(translate3d(0, 0, 0));
	}
	.owl-prev,
	.owl-next,
	.owl-dot {
		cursor: pointer;
		@include user-select(none);
	}
	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		@include user-select(none);
		.owl-text-select-on & {
			@include user-select(auto);
		}
		.owl-refresh & {
			display: none;
		}
		img {
			-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
		}
	}
	.owl-grab {
		cursor: move;
		cursor: -webkit-grab;
		cursor: -o-grab;
		cursor: -ms-grab;
		cursor: grab;
	}
	.owl-carousel.owl-rtl {
		direction: rtl;
	}
	.owl-carousel.owl-rtl .owl-item {
		float: right;
	}
	
	/* 
	 *   Owl Carousel - Lazy Load Plugin
	 */
	.owl-lazy {
		@include transition(opacity 400ms ease);
		opacity: 0;
	}
}


/* 
 *   Owl Carousel - Custom styles
 */
@mixin owl-carousel-custom(
	$disable-tile-height: true
) {
	.owl-carousel {
		overflow: hidden;
		white-space: nowrap;
		
		> .owl-carousel-item {
			display: inline-block;
			vertical-align: top;
		}
		
		.owl-item,
		.owl-carousel-item {
			white-space: normal;
		}
		
		.owl-item > .grid-tile {
			width: 100%;
		}
		
		@if $disable-tile-height {
			.grid-tile:before {
				content: none;
			}
		}
	}
	
	.owl-hide-controls {
		.owl-controls {
			display: none;
		}
	}
	
	.b-content-carousel {
		> .owl-carousel-item {
			width: 100%;
		}
	}
	
	.owl-prev,
	.owl-next {
		position: absolute;
		top: 0; bottom: 0;
		
		&:before {
			@include abs-center;
		}
	}
	
	.owl-prev {
		left: 0;
	}
	
	.owl-next {
		right: 0;
	}
	
	.owl-dots {
		text-align: center;
	}
	
	.owl-dot {
		display: inline-block;
	}
	
	.owl-dots,
	.owl-nav {
		&.disabled {
			display: none;
		}
	}
}
