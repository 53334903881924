@mixin tooltip($max-width: 300px, $bg-color: $color-black3) {
	.ui-helper-hidden-accessible {
		@extend %visually-hidden;
	}
	
	.b-tooltip {
		&,
		&-title {
			position: absolute;
			z-index: $z-tooltip;
			max-width: $max-width;
			padding: 5px 10px;
			background-color: $bg-color;
		}

		&-link {
			cursor: help;
		}

		&-content {
			display: none;
		}
	}
}
