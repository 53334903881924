.checkout-progress-indicator {
	margin: 15px 0;
	background-color: $color-none;
	line-height: 1;
	font-weight: 600;
	text-align: center;
	color: $color-grey_light;
	
	> div {
		display: inline-block;
		vertical-align: top;
		margin: 5px 7px;
		border-bottom: 1px solid transparent;
		text-transform: uppercase;
		cursor: default;
	}
	
	a {
		color: $color-grey2;
		text-decoration: underline;
		cursor: pointer;
		
		&:hover {
			text-decoration: none;
		}
	}
	
	> .active {
		&,
		a {
			color: $color-black;
			text-decoration: none;
		}
	}
	
	span {
		display: none;
	}
}
