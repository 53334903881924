@include owl-carousel-plugin;
@include owl-carousel-custom;

.owl-prev,
.owl-next {
	width: 80px;

	&:before {
		@include font(40px, false);
		color: $color-grey_light2;
		text-shadow: 1px 0px 3px fade-out($color-black, 0.5);
	}
}

.owl-prev {
	@include icon(arrow-left);
}

.owl-next {
	@include icon(arrow-right);
}

.owl-dot {
	width: 60px;
	height: 3px;
	background: $color-grey_light3;

	@include respond-to(phone) {
		width: 30px;
	}

	&.active {
		background: $color-black2;
	}

	& + & {
		margin-left: 25px;

		@include respond-to(phone) {
			margin-left: 15px;
		}
	}
}
