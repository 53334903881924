@mixin b-order_list(
	$buttons-alignment: right
) {
	.b-orders {
		&-list {
			tr {
				border: 0;
			}
		
			th,
			td {
				padding: 0 20px 0 0;
				vertical-align: top;
			}
			
			.order-date {
				width: 33%;
			}
		
			.order-shipped-to {
				width: 30%;
			}
		
			.order-total-price {
				width: 20%;
			}
		
			.order-details {
				min-width: 170px;
				padding-right: 0;
				text-align: center;
			}
		
			.value.order-processed {
				color: $color-orange; 
			}
		
			.value.order-closed,
			.value.order-shipped {
				color: $color-green;
			}
		
			.value.order-canceled {
				color: $color-red;
			}
		}
	
		&-item_return {
			clear: both;
			margin-top: 15px;
		}
	
		&-item_label {
			display: block;
			margin-bottom: 5px;
		}
	
		&-item_shipment + &-item_shipment {
			margin-top: 15px;
		}
	
		@include respond-to(not-phone) {
			&-item_info,
			&-item_info + &-item_return {
				float: $buttons-alignment;
			}
		}
		
		@include respond-to(phone) {
			&-item &-item_details {
				display: block;
				width: auto;
				margin-top: 15px;
				padding: 0;
				
				&:first-child {
					margin-top: 0;
				}
			}
		
			&-item_label {
				display: inline;
				
				.order-date & {
					display: inline-block;
				}
			}
		
			&-item_info,
			&-item_return {
				display: block;
				width: 100%;
				
				&.m-text {
					width: auto;
				}
			}
		}
	}
}
