@mixin minimizeHeader($speed: 0.1s) {
	.js-header_fixed {
		.b-site_header-wrapper,
		.b-cookies_message,
		.b-utility_menu,
		.b-header_tools .b-utility_menu-link {
			@include transition(all, $speed, linear);
		}

		&.js-header_minimized {
			.primary_logo {
				img {
					transition: width 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;
					width: 376px;
					will-change: width;
				}
			}
		}
	}

	.b-header-site_settings,
	.b-utility_menu {
		@include animation('animateOverflow 0.1s 0s 1 linear');

		.js-disable_header & {
			@include transition(none);
		}
	}

	.js-header_minimized {
		.b-site_header {
			background: $color-header;
		
			&-wrapper {}
		
			&-placeholder {
				background-color: $color-header;
			}
		}
		.b-header-site_settings,
		.b-cookies_message {
			max-height: 0;
			overflow: hidden;
		}

		.b-header-site_settings,
		.b-utility_menu {
			@include animation(none);
		}

		.b-utility_menu-popup.b-utility_menu-popup,
		.b-utility_popup.b-utility_popup {
			height: 0;
			overflow: hidden;
		}

		.b-header-promotion {
			max-height: none;
		}
	}
}

@include keyframes(animateOverflow) {
	0%   { overflow: hidden; }
	100% { overflow: hidden; }
}

@include respond-to(desktop) {
	@include minimizeHeader;

	.js-header_minimized {
		.b-utility_menu.b-header-left {
			opacity: 0;
			transition: opacity 0.8s cubic-bezier(0.5, 0, 0, 1) 0s, visibility 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;
		}

		.b-utility_menu-popup.b-utility_menu-popup,
		.b-utility_popup.b-utility_popup {
			height: auto;
		}

		.b-header_tools .b-utility_menu-link {
			line-height: $utility_menu-height_minimized;
		}
	}
}

@include respond-to(tablet) {
	@include minimizeHeader(0.2s);

	.js-header_minimized {
		.b-utility_menu {
			max-height: none;
		}
	}
}

@include respond-to(phone) {
	@include minimizeHeader(0.2s);
	.b-utility_menu {
		max-height: $utility_menu-height_mobile;
	}

	.js-header_minimized {
		.b-utility_menu {
			overflow: hidden;
		}
	}
}

