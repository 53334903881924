@include shopping_cart-base($secondary-blocks-gutter: 5px);
@include shopping_cart-table;

.b-cart {
	&-wrapper {
		@extend %checkout-primary;
		@include font($font_size, false);
		@include respond-to(desktop) {
			width: $primary-width_cart;
		}
	}

	&-title {
		@extend %checkout-secondary-title;

		.b-cart-countries_selector &,
		.b-cart-coupon_code & {
			@include font(16px, false);
		}

		.b-cart-gift_wrapping & {
			margin-bottom: 0;
		}
	}

	&-cell_price,
	&-cell_total {
		text-transform: uppercase;
	}

	&-cell_total:has(.b-cart-adjustments) {
		.b-order_table-label {
			vertical-align: top;
		}
	}

	&-table {
		.price-promotion:after,
		.promo-adjustment {
			@include font(11px, false);
			text-transform: none;
		}

		.promo-adjustment  > * span {
			display: contents;
		}

		.promo-adjustment  > * {
			white-space: nowrap;
		}
	}

	// Cart Coupons $ Promo
	&-row_coupons {
		.name {
			font-weight: 600;
			text-transform: uppercase;
		}
	}

	&-coupon {
		.value {
			color: $color-grey;
		}
	}

	&-cell_applied {
		.bonus-item {
			@include font(11px, false);
		}
	}

	&-promo {
		background-color: lighten($color-grey_light2, 2%);
	}

	.cart-promo-approaching {
		padding: 0 20px 20px;
		font-size: 1.2em;
		color: #52af52;
		text-transform: uppercase;
		font-weight: 500;
	}

	.error-form {
		background-color: #c00;
		color: #fff;
		padding: 5px;
		text-align: center;
		font-size: 12px;
		width: 100%;
		margin-bottom: 40px;
	}

	// Cart Actions
	&-actions {
		&.b-title_box {
			display: block;
			padding: 30px 0;

			> .b-content {
				padding: 0 $gutter-global;
			}
		}

		&.m-top {
			.dw-apple-pay-button {
				display: none;
			}
		}

		&.m-bottom {
			margin: 20px 0;

			@include respond-to(phone) {
				margin-bottom: 0;
				text-align: center;
			}
		}

		@include respond-to(not-phone) {
			.b-cart-empty & > .b-content:before {
				content: '';
				float: right;
				width: 130px;
				height: 1px;
			}
		}
	}

	&-action {
		&-continue_shopping {
			line-height: 28px;

			.m-text .b-button-inner {
				color: $color-black;
			}
		}

		&-checkout {
			.b-cart-actions.m-top & {
				margin: -10px 0;
			}
		}
	}

	&-checkout_message {
		font-size: 11px;
		color: $color-grey;
		line-height: 1em;
		margin: 15px 0;
		font-weight: 300;
	}

	&-continue_shopping {
		@include respond-to(phone) {
			display: none;
		}
	}

	&-update {
		@include icon(refresh);
		float: right;

		&:before {
			@include font(13px, false);
			line-height: 1;
			margin: -2px 5px 0 0;
		}

		&:hover:before {
			@include rotation();
		}

		@include respond-to(phone) {
			width: 100%;
			padding: 15px;

			&,
			&:before {
				@include font(15px, false);
			}
		}
	}

	&-totals {
		@extend %b-secondary;
		@extend %checkout-secondary;
	}

	&-countries_selector,
	&-order_totals,
	&-coupon_code,
	&-gift_wrapping {
		@extend %checkout-secondary-block;
	}

	.b-cart & {
		&-order_totals {
			.dw-apple-pay-button {
				width: 100%;
				margin-bottom: 10px;
				border-radius: 5px;
				@include respond-to(tablet) {
					width: 100%;
				}
			 }

			 .b-not_found {
				display: none;
			 }
		}
	}

	&-checkout_button {
		margin: 10px 0 10px;
	}

	&-checkout_button,
	.paypal-button-row,
	.dw-apple-pay-button,
	.gpay-button {
		width: 100%;
		max-width: unset;
		margin-bottom: 10px;
		border-radius: $button-border-radius;
	}

	.paypal-button-container {
		position: relative;
		z-index: 6;
	}

	&-coupon_code {
		@include b-coupon_code;

		.b-button {
			height: $input-height;
			color: $color-text;
			background: $color-grey_light4;
			border-color: $color-grey;

			&:hover {
				opacity: 0.7;
			}
		}

		&.m-used .b-cart-title {
			margin-bottom: 0;
		}
	}

	.b-product_carousel {
		margin: 0 $gutter-global;
	}

	.dw-apple-pay-button {
		@include b-button(
			$font-large: 600 15px / 18px $font_family
		);

		min-width: 166px;
		letter-spacing: 0.75px;
		padding: 15px 40px;
		margin: 0;
		border-radius: 0;

		@include respond-to(phone) {
			width: 100%;
			padding: 15px 40px;
			border-radius: 0;
			margin-bottom: 20px;
		}
	}
}

.order-totals-table {
	@include font($font_size, $letter_spacing-medium);
	word-break: break-word;

	@include respond-to(desktop-small) {
		@include font($font_size-small, false);
	}

	.order-gift-wrapping {
		margin-bottom: 13px;
	}
}

.b-customer_care {
	font-weight: 600;

	@include respond-to(tablet) {
		margin-top: 15px;
	}

	@include respond-to(phone) {
		margin-top: 20px;
	}

	&-link {
		@include font($font_size-small, $letter_spacing-medium);
		text-transform: uppercase;
	}
}

@include respond-to(desktop-small) {
	td.b-cart-cell_image,
	.b-summary-table .b-cart-cell_details {
		float: none;
	}
}

.order-duties {
	$selector: &;

	&#{$selector}--mobile .order-duties-title {
		&::before {
			background-color: $color-grey_light4;
		}
	}
}

.order-duties-content {
	background-color: $color-grey_light4;
}
