@include b-order_table(
	$include-simple: false
);
@include b-order_product;

.b-order_table {
	border-bottom: 0;
	
	&-header {
		&-cell {
			background-color: $color-table-header;
			@include font($font_size-small, false);
			line-height: 1;
			color: $color-grey;
			text-transform: uppercase;
		}
	}

	&-row {
		border-top: 5px solid $color-grey_light4;
		background-color: $color-table-item;
	}
	
	&-label {
		font-weight: 600;
		text-transform: uppercase;
	}

	&-image_wrapper {
		display: flex;
		flex-direction: column;
		margin-bottom: 25px;
		
		@include respond-to(phone) {
			margin-bottom: 10px;
		}
	}

	&-fake_button_return {
		margin-bottom: 5px;
		align-self: flex-start;
	}

	&-description_return {
		display: block;
		font-size: 10px;

		@include respond-to(phone) {
			margin-bottom: 15px;
		}
	}
}

.b-order_product {
	&-info {
		@include font($font_size-small, false);
	}

	&-name {
		@include font($font-size, false);
		font-weight: 600;
		text-transform: uppercase;
	}

	&-message,
	&-sku {
		color: $color-grey;
	}

	&-attribute {
		font-weight: 600;
	}
}
