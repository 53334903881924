#QuickViewDialog {
	.pdp-main > .b-pdp-product_name {
		display: none;
	}

	.pdp-main,
	.b-pdp-product_view,
	.b-pdp-product_images,
	.b-pdp-product_info {
		margin: 0;
		padding: 0;
	}

	.b-pdp-general_info {
		margin-top: 10px;
	}

	.b-zoom-target,
	.b-pdp-accordeon,
	.b-pdp-product_actions {
		display: none;
	}

	.b-pdp-add_to_cart_form {
		width: 100%;
		margin: 0;
	}

	.b-pdp_carousel-item {
		display: none;

		&:first-child {
			display: block;
		}
	}

	.b-pdp-attribute.attribute-size .b-swatches-list {
		top: auto; bottom: 100%;
	}

	@include respond-to(not-phone) {
		.b-pdp-product_info_wrapper,
		.b-pdp-product_images {
			float: left;
			width: 50%;
			margin: 0;
		}

		.b-pdp-product_info_wrapper {
			float: right;
			padding-left: 20px;
		}

		.b-pdp-product_info {
			text-align: left;
		}

		.b-pdp-pricing {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.dialog-quickview {
	max-width: 760px;
	.b-size_links-container,
	.ui-dialog-title {
		display: none;
	}
	.b-pdp-size_recomm {
		padding: 0 0 10px;
		font-size: 11px;
	}
}
