@include b-order_list;

.b-orders {
	&-list {
		@include font($font_size-small, false);
	}

	&-item {
		padding: 25px;
		margin-bottom: 5px;
		background-color: $color-account-block;
	}

	&-item_label {
		font-weight: 700;
		text-transform: uppercase;
	}
}
