.b-not_found {
	padding: 100px 30px;
	color: $color-text_light;
	text-align: center;
	
	@include respond-to(desktop) {
		width: 50%;
		margin: 0 auto;
	}
	
	@include respond-to(phone) {
		padding: 70px 0;
	}
	
	&-title {
		@extend %heading-2;
		font-weight: 400;
	}
	
	&-message {
		margin: 30px 0 0;
		@include font(12px, false, $font_family-additional);
		color: $color-black;
		line-height: 1.5;
		
		@include respond-to(phone) {
			line-height: 30px;
		}
	}
	
	&-search_form {
		margin: 60px auto 0;
		
		&.b-search-form form {
			text-align: center;

			@include respond-to(phone) {
				max-width: 360px;
				width: 100%;
			}
		}
	}
	
	&-search_title {
		@include font(18px, false);
		color: $color-text;
		line-height: 1.5;
		text-transform: uppercase;
	}
}
