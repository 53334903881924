.b-header_tools {
	@include respond-to(desktop) {
		margin-right: $gutter-global;
		float: right;
		clear: right;
		z-index: $z-min + 1;
	}
	
	.b-utility_menu-link {
		@include font(0, 0);
	}
}

.b-header-site_settings {
	@include respond-to(desktop) {
		float: left;
		margin: 15px 0 0 $gutter-global + $logo-width;
	}
}

.b-header-sign_in {
	@include respond-to(desktop) {
		float: right;
		margin: 15px $gutter-global 0 0;
	}
}
