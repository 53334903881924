@include b-wishlist;
@include b-wishlist_remove;
@include b-wishlist_move;

.b-wishlist {
	.b-order_table {
		&-row {
			border-top-width: 1px;
			
			&:first-child {
				border-top: 0;
			}
			
			@include respond-to(phone) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
