@mixin checkout-billing {
	.b-checkout-discount_payments {
		.form-row {
			margin: 0;
		}

		.f-label {
			margin: 15px 0 5px;
		}

		.coupon-apply,
		.giftcert-apply,
		.f-field-text.f-field-text {
			display: inline;
			margin: 0;
			padding: 0;
		}

		#dwfrm_billing_couponCode,
		#dwfrm_billing_giftCertCode {
			float: left;
			width: 205px;
			margin-right: -1px;
		}

		#dwfrm_billing_couponCode {
			@include respond-to(phone) {
				width: 70%;
			}
		}

		#dwfrm_billing_giftCertCode {
			@include respond-to(phone) {
				width: 100%;
			}
		}

		.f-caption {
			display: none;
		}

		&-privacy_policy {
			@include flexbox();
			align-items: baseline;
			margin: 20px 0 15px;
		}

		&-privacy_label {
			color: $color-grey;
			font-size: 14px;
		}

		.b-button {
			margin: 0;
			padding-left: 15px;
			padding-right: 15px;
			white-space: nowrap;
			
			+ .b-button {
				margin-left: 20px;
				
				@include respond-to(phone) {
					margin-left: 0;
				}
			}
		}

		.coupon-apply {
			@include respond-to(phone) {
				.b-button {
					float: left;
					width: 30%;
				}
			}
		}

		.giftcert-apply {
			@include respond-to(phone) {
				display: block;
				
				.b-button {
					width: 100%;
					margin-top: 10px;
				}
			}
		}

		.tooltip {
			display: none;
		}

		.f-description {
			margin-top: 5px;
		}
		
		.redemption {
			clear: both;
			@include font($font_size-small, false);
			color: $color-grey;
		}
		
		.success {
			margin-top: 5px;
			color: $color-green;
		}

		.remove {
			@include font(0, 0);

			&:before {
				@include font($font_size-small, false);
				margin-top: 1px;
				vertical-align: top;
			}
		}
	}

	// Payment Methods

	.payment-method {
		display: none;
		
		&.payment-method-expanded {
			display: block;
		}
	}

	.b-checkout-credit_card_form {
		@include respond-to(not-phone) {
			width: 50%;
			
			.f-select,
			.f-textinput {
				width: 100%;
			}
		}

		.f-description {
			margin-bottom: 5px;
		}
	}

	// Credit Card Grid
	.b-credit_card {
		position: relative;
		margin-bottom: 10px;
		
		@include respond-to(not-phone) {
			@include flexbox;
			width: 50%;
			padding-left: 10px;
		}

		&:before {
			position: absolute;
			top: 15px; right: 15px;
		}

		&-list {
			@include respond-to(not-phone) {
				@include flexbox;
				@include wrap;
				max-width: 600px;
				margin-left: -10px;
			}
		}

		> .f-label {
			width: 100%;
			padding: 15px 15px 15px 40px;
			border: 1px solid $color-grey_light2;
			
			&:before {
				float: left;
				margin-left: -25px;
			}
		}

		> .f-radio:checked ~ .f-label {
			border-color: $color-grey_light;
		}

		&-name,
		&-number {
			margin-right: 50px;
		}

		&-type {
			display: none;
		}

		&-remove,
		.f-field-cvn {
			margin-top: 10px;
		}
		
		.f-field-cvn {
			margin-bottom: 0;
			
			.f-label {
				display: none; 
			}
		}
	}

	.b-checkout-preselected_address {
		.b-button {
			margin-top: 10px;
		}
	}

	// Change order of components when billing adress is preselected
	.b-checkout-billing.m-address_preselected {
		@include flexbox;
		@include flex-column;
		
		.b-checkout-payment_methods {
			@include order(1);
		}
		
		.b-checkout-discount_block {
			@include order(2);
		}
		
		.b-checkout-address_block {
			@include order(3);
		}
		
		.b-checkout-actions {
			@include order(4);
		}
	}
}