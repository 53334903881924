@include stop_fake_form;

.b-stop_fake_form,
.b-stop_fake_feed {
	&-message {
		&.m-success,
		&.m-error {
			margin-bottom: 15px;
		}
		
		&.m-success {
			color: $color-green;
			
			&:before {
				content: icon-char(form-checkbox);
				padding: 1px;
			}
		}
		
		&.m-error {
			color: $color-red;
			
			&:before {
				content: "!";
				border: 1px solid $color-red;
				border-radius: 50%;
				font-family: $font_family;
				font-weight: 700;
			}
		}
	}
}
