@mixin spinner {
	.ui-spinner {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 120px;
		height: auto !important;
		padding: 0 40px;
		
		&-button {
			@include user-select(none);
			position: absolute;
			top: 0;
			cursor: pointer;
			padding: 0 10px;
			@include font(0, 0);
			line-height: $input-height;
			color: $color-text_light;
			
			&:hover {
				opacity: 0.8;
			}
			
			&.js-disabled-spiner {
				opacity: 0.3;
				cursor: default;
			}
			
			.ui-icon:before {
				font-size: 18px;
			}
		}
	
		&-up {
			right: 0;
		}
	
		&-down {
			left: 0;
		}
		
		span.error {
			margin: 0 -40px;
			text-align: center;
			font-size: 10px;
			letter-spacing: 0;
		}
		
		input {
			width: 100%;
			text-align: center;
			
			&.error {
				margin: 0;
			}
		}
		
		input[type=number] {
			margin: 0;
		}
	}
		
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none;
		margin: 0; 
	}
	
	input[type=number] {
		max-width: 40px;
		margin-left: 40px;
		margin-right: 40px;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
		-moz-appearance: textfield;
	}
}
