.customization {
    text-align: center;
    padding: 20px 0;

    &-button {
        width: 100%;
        margin-bottom: 20px;
    }

    &.m_customized {
        border: 1px solid $color-grey_light;
        margin-bottom: 10px;
        padding: 10px 0;
    }

    &-title {
        text-transform: uppercase;
        color: $color-grey2;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 15px;
    }

    &-delete-link {
        margin-top: 10px;
        display: block;
    }

    &-preview .b-button-inner {
        color: $color-black3;
        font-weight: bold;
    }

    &-popup {
        text-align: center;
    }
}