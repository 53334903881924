@include plp-product_tile(
	$tile-ratio: 1.75,
	$tile-ratio-product: 1.5115
);

.tiles-container {
	.b-search_results-product_list & {
		display: inline;
	}
}

.b-search_results-product_list {
	.grid-tile:not(.grid-banner) {
		padding: 0 $plp-grid-section-gap-mobile;

		@include respond-to(desktop) {
			padding: 0 $plp-grid-section-gap-desktop $plp-grid-section-bottom-gap-desktop-px;
		}

		@media only screen and (min-width: 1440px) {
			padding: 0 $plp-grid-section-gap-desktop-px $plp-grid-section-bottom-gap-desktop-px;
		}
	}

	.grid-tile {
		&:before {
			padding-bottom: 0; // not needed fake gap for tiles
		}
	}
}

.wide-tiles {
	.b-search_results-product_list {
		.grid-tile {
			@include respond-to(tablet) {
				width: 33.333%;
			}
			@include respond-to(phone) {
				width: 50%;
			}
		}
	}
}

.b-product_tile {
	@include font($font_size, false, $font_family);
	color: $color-black;
	margin: 0 5px $plp-grid-section-gap-desktop-px;
	text-align: left;

	&:hover {
		.b-product_tile-image {
			@include respond-to(not-phone) {
				border-color: $color-black;
			}
		}
	}

	@include respond-to(phone) {
		margin: 0 1px 40px;
	}

	&-info_wrapper {
		margin-bottom: 15px;
	}

	&-image {
		margin-bottom: 0;
		border: 1px solid transparent;
	}

	&-name {
		@include font($font_size);
		line-height: 1;
		margin-bottom: 7px;
		display: flex;
		flex-direction: column;
		gap: 3px;

		@include respond-to(desktop) {
			@include font($font_size, 0.5px);
		}

		&::before {
			content: "we accept crypto";
			display: block;
			color: #999;
			font-size: 8.5px;
			text-transform: uppercase;
		}
	}

	&-name_link {
		color: $color-black;

		h2 {
			text-wrap: balance;
		}
	}

	@include quick-view-link;

	&-pricing {
		> span {
			@include font($font_size);
			display: inline-block;
			line-height: 1;
		}

		.product-sales-price {
			font-weight: 700;
			font-size: 1.25rem;
		}

		.product-standard-price {
			color: $color-grey2;

			& + .product-sales-price {
				color: #e91717;
				border-left: 1px solid $color-grey;
				margin-left: 5px;
				padding-left: 9px;
			}
		}
	}

	&-promotion {
		@include font(12px, false);
		display: block;
		font-weight: 900;
		line-height: 20px;
		color: $color-red2;
	}

	&-label_wrapper {
		min-height: 20px;
		margin-bottom: 7px;
	}

	&-label {
		@include label($font_size-small);
		position: static;
		color: #000;
		background: #e5e5e5;
		padding: 6px;
	}

	&-action_wrapper {
		margin-bottom: 15px;
	}

	&-action {
		bottom: 1px;
		width: auto;
		background: fade-out($color-bg, 0.1);
		@include font(0, 0);
		left: 1px;
		right: 1px;
	}

	&-sizes {
		padding: 5px;
		border: 0;
	}

	&-size {
		@include font(11px);
		color: $color-grey;
		font-weight: 600;
		line-height: 1;
		margin: 5px 0;
		padding: 0 10px;
	}

	&-size + &-size {
		border-left: 1px solid $color-grey2;
	}

	&-size_list {
		margin: 0;
	}

	&-swatches {
		margin-bottom: 7px;

		@media screen and (max-width: 540px) {
			display: none;
		}

		&-item {
			display: inline-block;
			vertical-align: middle;
			margin: 5px 0;
		}

		&-link {
			position: relative;
			display: block;
		}
	}

	&-wishlist {
		position: absolute;
		top: 10px;
		right: 10px;
		color: $color-black;
		cursor: pointer;

		&.icon-star.active {
			&:before {
				content:"\f16b";
			}
		}

		span {
			display: none;
		}

		@include respond-to(phone) {
			width: 42px;
			height: 42px;
			padding-top: 10px;
			padding-right: 10px;
			top: 0;
			right: 0;

			&::before {
				float: right;
			}
		}
	}

}

.grid-banner {
	.b-banner {
		&.m-left {
			padding-right: 1px;
		}

		&.m-right {
			padding-left: 1px;
		}
	}
}

.product-review {
	display: none;
}

.plp-wide-slot {
	width: 100% !important;
}
