@mixin b-order_table (
	$image-width: 120px,
	$border-color: $color-grey_light2,
	$include-simple: true
) {
	.b-order_table {
		border: solid $border-color;
		border-width: 0 0 1px;

		&-row {
			position: relative;
			border-top: 1px solid $border-color;
			border-bottom: 0;
		}

		&-cell,
		&-header-cell {
			vertical-align: middle;
			padding: 20px 10px;
			text-align: left;
		}

		&-header-cell {
			&:first-child {
				padding-left: 20px;
			}

			&:last-child {
				padding-right: 20px;
			}
		}

		&-header {
			border-bottom: 0;
		}

		&-cell {
			&.b-cell-image {
				width: $image-width;
				max-width: $image-width;

				img {
					width: $image-width - 20px;
				}
			}

			&.b-cell-details {
				overflow: hidden;
			}
		}

		&-value {
			.b-cell-quantity &,
			.b-cell-price & {
				white-space: nowrap;
			}

			.price-standard {
				display: block;
			}

			.promo-adjustment {
				white-space: normal;
			}
		}

		&-action {
			margin-top: 10px;
		}

		.b-cell-quantity {
			@include respond-to(not-phone) {
				text-align: center;
			}
		}

		@include respond-to(phone) {
			&-row,
			&-cell {
				display: block;
				text-align: left;
			}

			&-row {
				padding: 15px;
			}

			&-cell {
				&.b-order_table-cell {
					clear: left;
					width: auto;
					padding: 5px 0;
				}

				&.b-cell-image {
					float: left;
				}

				&.b-cell-details,
				&.b-cell-delivery_options,
				&.b-cart-cell_applied {
					clear: none;
					margin-left: $image-width;
				}
			}

			&-header-cell {
				display: none;

				&:first-child {
					display: block;
				}
			}

			&-label,
			&-value {
				display: inline-block;
				vertical-align: middle;
			}

			&-label {
				width: $image-width;
				padding-right: 10px;
			}
		}
	}

	@if $include-simple {
		.b-order_table-simple {
			.b-order_table {
				&-cell,
				&-header-cell {
					&:first-child {
						padding-left: 0;
					}
				}

				@include respond-to(phone) {
					&-row {
						margin: 0;
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}
