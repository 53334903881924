.b-login {
	@include flexbox;
	@include wrap;

	&-item {
		@include font(13px, 0);
		line-height: 1.5;
	}

	&-iframe {
		vertical-align: top;
		overflow: hidden;
	}

	&-title {
		@extend %heading-3;
		margin-bottom: 15px;
	}
	
	&-title_main {
		@extend %heading-1;
		width: 100%;
		margin-bottom: 45px;
		text-align: center;
		
		@include respond-to(not-desktop) {
			display: none;
		}
	}

	&-description {
		margin-bottom: 15px;
		
		.p-login_popup .b-login-item_login & {
			display: none;
		}
	}

	p,
	ul {
		margin-bottom: 15px;
	}
	
	ul {
		@include font(12px, false);
	}
	
	b {
		font-weight: 600;
	}
	
	.b-button {
		width: 100%;
		
		&.m-text {
			width: auto;
		}
	}

	.f-textinput {
		width: 100%;
	}

	.f-label {
		display: none;
	}

	.b-button-social {
		padding-left: 7px;
		padding-right: 7px;

		&:before {
			float: left;
			@include font(20px, false);
		}
		
		&:after {
			content: '';
			display: inline-block;
			width: 20px;
		}
	}

	@include respond-to(desktop) {
		&-item {
			@include flex(1);
			position: relative;
			padding: 0 5%;
		}
		
		&-item + &-item {
			&:after {
				content: '';
				position: absolute;
				top: 0; left: 0;
				height: 100%;
				border-left: 1px solid $color-grey_light;
			}
		}
	}

	@include respond-to(not-desktop) {
		@include flex-column;

		&-item {
			margin-top: 25px;
			padding-top: 25px;
			@include font($font_size-small, false);
			line-height: normal;
			border-top: 1px solid $color-grey_light;

			&_register {
				@include order(1);
				margin-top: 0;
				padding-top: 0;
				border-top: 0;
			}

			&_login {
				@include order(2);
			}

			&_social {
				@include order(3);
			}
		}

		&-iframe {
			height: $iframe_login-height_mobile;
		}

		.b-button-social {
			&:before {
				float: none;
				margin-right: 10px;
			}
		}
	}

	@include respond-to(tablet) {
		width: 40%;
		margin: 0 auto;
	}
}
