.b-pdp {
	&-store_availability {
		display: none;
	}

	&-add_to_cart_form {
		order: 2;
		margin-bottom: $pdp-info-section-indent;

		@include respond-to(desktop) {
			@include font();
			width: 100%;
		}
	}

	&-product_actions {
		order: 1;
		margin-bottom: 15px;
		@include respond-to(not-desktop) {
			width: 100%;
		}
	}
	&-help_action {
		display: none;
	}
	&-product_actions {
		order: 1;
		&_wrapper {
			display: flex;
			flex-direction: column;
		}
	}


	&-add_to_cart {
		width: 100%;

		&.m-large {
			padding-left: 10px;
			padding-right: 10px;
		}

		> span {
			pointer-events: none;
		}
	}

	&-favorite_button.m-text,
	&-request_info-link.m-text {
		@include font(12px, 1px);
		text-align: left;
		text-decoration: none;
		text-transform: uppercase;

		&:before {
			display: inline-block;
			margin: -3px 5px 0 0;
			@include font(15px, false);
		}
	}

	&-favorite_button {
		@include icon(star);

		&.b-favorite-added,
		&:hover {
			 &:before {
				content: icon-char(star-filled);
			}
		}

		&.b-favorite-added:hover {
			 &:before {
				content: icon-char(star);
			}
		}

		&:hover {
			.b-favorite_message-added {
				display: none;
			}

			.b-favorite_message-remove {
				display: inline;
			}
		}

		// Wishlist button when size is not selected yet
		&_disabled {
			&.m-text {
				cursor: default !important;
				line-height: 18px;
			}
			&.m-text .b-button-inner,
			&.m-text .b-button-inner-note {
				color: $color-grey_light;
			}
			.b-button-inner-note {
				display: none;
			}
			&:hover {
				&:before {
					display: none;
				}
				.b-button-inner {
					display: none;
				}
				.b-button-inner-note {
					display: inline-block;
				}
			}
		}
	}

	&-gift_registry {
		display: none;
	}
}
