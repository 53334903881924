@mixin b-back_to_top-button(
	$selector: '.b-back_to_top-button',
	$button-size: 35px
) {
	#{$selector} {
		@include icon(arrow-up);
		@include font(0, 0);
		@include transition;
		position: fixed;
		bottom: 30px; right: 10px;
		width: $button-size; height: $button-size;
		cursor: pointer;
		text-align: center;
		
		opacity: 0;
		pointer-events: none;
		
		&:before {
			font-size: 7px;
			line-height: $button-size;
		}
		
		&.js-showed {
			opacity: 1;
			pointer-events: auto;
		}
	}
}
