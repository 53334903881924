.b-site_header-simple {
	overflow: hidden;
	border-bottom: 1px solid $color-black2;
	
	.primary_logo {
		@include respond-to(desktop) {
			margin: ($header-height - $logo-height)/2 auto;
		}
		
		@include respond-to(tablet) {
			position: static;
			width: 100%;
		}
	}
}
