@mixin dangerousWidth {
    @media only screen and (min-width: 768px) and (max-width: 890px) {
        @content
    }
}

$dangerousWidthIconSize: 1.6rem;

.b-utility_menu {
    @include font(0, 0);
    position: relative;
    text-align: right;

    &:first-of-type {
        text-align: left;
    }

    @include respond-to(phone) {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &-item {
        display: inline-block;
        vertical-align: top;
        @include font;
        text-align: left;

        @include respond-to(desktop) {
            position: relative;
        }

        @include respond-to(phone) {
            flex: 1;
            &:before {
                content: none;
                float: left;
                width: 1px;
                height: 20px;
                background: $color-black3;
                margin: 2px -1px;
            }
        }
        @include dangerousWidth {
            min-width: 33px;
            &.b-header-minicart {
                padding-right: 0;
            }

        }

        &.header-menu_toggle {
            margin-left: 10px;
            margin-right: 10px;

            @include respond-to(desktop) {
                margin-left: 30px;
            }

            @include respond-to(phone) {
                margin-left: 6px;
            }
        }

        &.b-header_sign,
        &.b-header_wishlist {
            @include respond-to(phone) {
                display: none;
            }
        }
    }

    &-link {
        @extend %utility_menu-link;

        &:hover {
            color: $color-black;
        }

        .b-utility_menu-item:first-child > & {
            border-left: 0;
        }

        &.active {
            @extend %utility_menu-link-active;
        }

        &.icon-magnifier {
            @include respond-to(not-phone) {
                padding-left: 20px;
                padding-right: 0;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                @include font(12px);

                span {
                    margin-left: 10px;
                }
            }

            @include respond-to(phone) {
                padding-right: 5px;
            }

            &.js-utility_flyout-link.active {
                pointer-events: unset;
            }

            &.active::before {
                content: "\f134";
                @include font(15px);
            }
        }

        &.icon-menu,
        &.icon-star {
            // hack to increase icons width
            -webkit-text-stroke-width: 0.5px;

            // fallback for IE
            @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
                text-shadow: 0px 0px 0px $color-black,
                0px 0px 0px $color-black,
                0px 0px 0px $color-black;
            }
        }

        &.icon-crypto {
            &:before {
                content: "";
                background-image: url("../images/crypto-icon-btc.svg");
                background-size: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                display: inline-block;
                vertical-align: middle;
                width: 1.38462rem;
                height: 1.38462rem;
                @include respond-to(phone) {
                    width: 1.69231rem;
                    height: 1.69231rem;
                }
                @include dangerousWidth {
                    width: $dangerousWidthIconSize;
                    height: $dangerousWidthIconSize;
                }
            }
        }

        &.icon-star {
            &.active {
                opacity: 1;

                &:before {
                    content: "\f16b";
                }
            }
        }
    }

    &-fake_link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &-popup {
        @extend %utility_menu-popup;
        display: none;
    }

    &-popup &-link {
        color: $color-text;
    }
}

.b-utility_popup {
    position: absolute;
    width: 100%;
    z-index: $z-min;
    background: none;

    @include respond-to(not-desktop) {
        @include order(3);
        top: 100%;
        left: 0;
    }

    &-item {
        display: none;
        width: 100%;
        background-color: $color-white;

        @include respond-to(desktop) {
            position: relative;
        }

        @include respond-to(not-desktop) {
            position: absolute;
            top: 0;
            right: 0;
        }

        @include respond-to(tablet) {
            &.b-header-login {
                float: right;
                width: 320px;
            }
        }

        @include respond-to(phone) {
            width: 100%;
        }

        &.active {
            .desktop & {
                display: block;
            }

            @include respond-to(not-desktop) {
                position: static;
            }
        }

        &.js-iframe_loading {
            display: block;
            height: 0;
            overflow: hidden;
        }
    }

    &-content {
        padding: 30px 0;
        background: $color-white;

        @include respond-to(not-desktop) {
            padding: 30px;
        }

        &.b-login {
            max-width: $content-width;
            margin: 0 auto;
        }
    }

    &-close {
        position: absolute;
        top: 30px;
        right: 25px;
        color: $color-black;
        background-color: $color-white;
        cursor: pointer;

        &:before {
            @include font(20px, 0);
        }
    }
}

.icon-user {
    @include icon(account);
}

%utility_menu-link {
    @include font(10px);
    display: block;
    padding: 0 10px;
    line-height: $utility_menu-height;
    color: $color-black;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;

    @include respond-to(tablet) {
        line-height: $utility_menu-height_tablet;
    }

    @include respond-to(phone) {
        line-height: 55px;
    }

    &:before {
        line-height: 1;

        @include respond-to(desktop) {
            @include font(18px, false);
            display: inline-block;
            vertical-align: middle;
        }

        @include respond-to(tablet) {
            @include font(22px, false);
        }

        @include dangerousWidth {
            font-size: $dangerousWidthIconSize;
        }

        @include respond-to(phone) {
            @include font(16px, false);
        }
    }

    > span {
        display: inline-block;
        line-height: 1;

        @include respond-to(not-desktop) {
            display: none;
        }
    }
}

%utility_menu-link-active {
    color: $color-black;
    opacity: 0.5;

    &:before {
        @include respond-to(not-desktop) {
            content: icon-char(close);
        }
    }
}

%utility_menu-popup {
    position: absolute;
    z-index: $z-utility-popup;
    top: 100%;
    right: 0;
    min-width: 160px;
    background-color: $color-utility_popup;
    box-shadow: 3px 3px 6px 0 fade-out($color-black, 0.9);

    @include respond-to(not-desktop) {
        width: 100%;
    }
}

.desktop .js-header-flyout_opened {
    overflow: hidden;

    .ui-widget-overlay {
        display: none;
    }

    // Add scrollbar to page wrapper to prevent page scrolling and header minimization
    #wrapper {
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

.js-header-flyout_opened {
    @include respond-to(desktop) {
        &:before {
            content: '';
            position: fixed;
            z-index: $z-utility-flyout;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color-white;
        }

        .b-utility_popup-item {
            height: 0;
            background: $color-white;
        }

        .b-header-promotion {
            display: none;
        }
    }
}
