.b-login-item_social {
	.b-button-social {
		position: relative;
		width: 100%;
		height: $social_button-height;
		display: flex;
		justify-content: center;
		gap: 10px;
		align-items: center;
		transition: all .2s ease-in;
		span {
			display: block;
			flex: 1;
			text-align: left;
			font-size: 13px;
			font-weight: 600;
			line-height: 1;
			color: $color-white;
			text-transform: uppercase;
		}
		&:before {
			content: "";
			flex: 1;
			display: block;
			width: calc(#{$social_button-height} - 12px);
			height: calc(#{$social_button-height} - 12px);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: right center;
		}
		&:hover {
			&:before {
				filter: invert(1);
			}
			span {
				color: $color-black;
			}
		}
		&_facebook:before {
			background-image: url("../icons/icon-facebook-white.svg");
		}
		&_google:before {
			background-image: url("../icons/icon-google-white.svg");
		}
		&_apple {
			#appleid-signin { // visually hide stock button text&icon
				position: absolute;
				opacity: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}
			&:before {
				background-image: url("../icons/icon-apple-white.svg");
			}
		}
		&_twitter:before {
			background-image: url("../icons/icon-twitter-white.svg");
		}
	}
}