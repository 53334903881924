.b-pdp {
  &-variations {
    
    @include respond-to(not-desktop) {
      margin: 20px 0 0;
    }
  }
  &-size_recomm {
    color: $color-black;
    padding: 10px 0;
    font-size: 12px;
    font-weight: 600;
  }
  &-attribute {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border-top: 1px solid $color-pdp;
    padding: $pdp-info-section-indent 0 10px;
    
    .label {
      margin-bottom: 15px;
      @include font($font_size, $letter_spacing-medium);
      text-transform: uppercase;
      
      @include respond-to(not-desktop) {
        margin-bottom: 15px;
      }
      
      &.toggle {
        cursor: default;
        
        &:after {
          display: none;
        }
      }
      
      .label-name {
        font-weight: 600;
      }
      
      > .selected-text,
      > .selected-value {
        display: none;
      }
    }
    
    .b-swatches-item {
      &.unselectable {
        .b-swatches-tooltip,
        .b-swatches-text {
          color: $color-grey_light;
        }
        
        .b-swatches-image {
          opacity: 0.5;
        }
      }
    }
    
    .b-swatches-last {
      font-size: 12px;
    }
    
    .b-swatches-link {
      display: flex;
      text-decoration: none;
      text-transform: uppercase;
    }
    
    .b-swatches-tooltip,
    .b-swatches-text {
      @include font($font_size, false);
      color: $color-text_light;
    }
    
    .b-swatches-image {
      max-width: 26px;
      max-height: 14px;
      border: 1px solid $color-pdp;
      overflow: hidden;
      @include font(0, 0);
      
      &.large {
        overflow: auto;
        padding: 5px 0;
        border: none;
        max-width: 95px;
        max-height: 100%;
      }
    }
    
    &.attribute-color {
      .b-swatches-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-left: 1px;
        margin-right: 10px;
        
        .b-swatches-hexagon {
          @include hexagon-border;
        }
      }
      
      .label-name:after {
        content: ':';
      }
      
      .selected-value {
        display: inline;
        color: $color-grey;
        text-transform: capitalize;
      }
    }
    
    &.attribute-size {
      > .label {
        display: none;
      }
      
      .error-message {
        flex: 1 0 100%;
      }
      
      .value.toggle-swatch {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;
        margin-top: 5px;
        @media screen and (max-width: $max-micro-phone-width) {
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          .b-swatches, .b-size_links-container {
            width: 100%;
            max-width: none;
          }
          .b-size_links-container {
            text-align: left;
          }
        }
      }
      
      .b-swatches {
        position: relative;
        flex: 0 0 auto;
        min-width: 180px;
        max-width: 220px;
        @include font(13px, false);
        line-height: 35px;
        &-value {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          padding: 0 0 0 12px;
          font-weight: 400;
          border: 1px solid $color-grey_light3;
          background-color: $color-white;
          
          &:after {
            width: 35px;
            margin: 0;
            border-left: 1px solid $color-grey_light3;
            @include font(4px, 0);
            text-align: center;
          }
        }
        
        &-list {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          min-width: 100%;
          max-height: 200px;
          overflow: auto;
          margin: -1px 0;
          border: 1px solid $color-grey_light3;
          background-color: $color-white;
          z-index: $z-min + 1;
          
          &::-webkit-scrollbar {
            width: 20px;
            background-color: $color-grey_light2;
          }
          
          &::-webkit-scrollbar-thumb {
            width: 16px;
            background-color: $color-white-transparent;
            border: 4px solid $color-grey_light2;
            border-radius: 10px;
          }
        }
        
        &-item {
          border-bottom: 1px solid $color-grey_light3;
          &:last-child {
            border-bottom: 0;
          }
        }
        
        &-link {
          @include text-ellipsis;
          padding: 0 15px;;
          text-transform: none;
          
          &.js-back_in_stock {
            cursor: pointer;
            &:hover {
              background-color: $color-grey_light3;
            }
          }
        }
        
        &-text {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        &-size {
          display: inline-block;
          color: $color-text_light;
        }
        &-last,
        &-tooltip {
          display: inline-block;
          font-size: 11px;
          text-align: right;
          color: $color-text_details;
        }
      }
      
      .expanded + .b-swatches-list {
        display: block;
      }
      
      .selected-text {
        color: $color-grey2;
        white-space: nowrap;
      }
      
      .selectable .b-swatches-link,
      .unselectable .js-back_in_stock {
        &:hover {
          background-color: $color-grey_light4;
        }
      }
      
      .unselectable {
        .b-swatches-size {
          color: $color-grey_light;
          text-decoration: line-through;
        }
        .b-swatches-tooltip {
          color: $color-text_details;
        }
      }
    }
  }
}
