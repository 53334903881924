@include b-store_locator;

.b-store_locator {
	.breadcrumb {
		display: none;
	}

	~ .b-footer {
		margin-top: 0;
	}

	&-no_results {
		@include font($font_size, false);
	}

	&-results_list {
		.b-store_result-actions {
			margin-top: -5px;
		}

		.b-store_result-title {
			margin-bottom: 15px;
		}

		.b-store_result-list {
			border-width: 1px 0;
		}
	}

	&-details_back {
		margin-bottom: $gutter-global;
	}

	&-details_title {
		@extend %heading-1;
	}

	&-details_info {
		@include font($font-size, $letter_spacing-medium);
		line-height: normal;
		color: $color-black3;

		.b-store-label {
			@extend %heading-3;
		}

		.b-store-address {
			margin-bottom: 15px;
		}
	}
}

.b-direction {
	&-title {
		@extend %heading-3;
	}

	&-description {
		margin-bottom: 10px;
		@include font(13px, false);
	}

	&-mode {
		.b-button {
			&.active {
				background-color: $color-black3;
				color: $color-white;
			}
		}
	}

	&-route_header {
		margin-top: $gutter-global;
		padding: 10px 0;
		border-top: 1px solid $color-grey_light2;
		@include font(15px, 0);
	}

	&-route_table {
		tr {
			border-top: 1px solid $color-grey_light2;
			border-bottom: 0;
		}

		td {
			vertical-align: top;
			padding: 7px 5px;
		}
	}

	&-copyright {
		@include font(12px, 0);
		text-align: right;
		color: $color-grey_light;
	}
}

.b-route {
	@include font(13px, 0);
}

@include respond-to(not-phone) {
	.b-store_locator {
		&-search_distance {
			border-left: 1px solid $color-grey_light2;
		}

		&-details_info,
		&-details_direction  {
			padding: $gutter-global;
			background-color: $color-white;
		}

		&-details_map_direction {
			&,
			&.toggle-content {
				@include flexbox;
			}
		}
	}
}

@include respond-to(desktop) {
	.b-store_locator {
		&-elements {
			max-width: none;
		}

		&-results {
			@include calc(height, '100vh - #{$header-height} - 200px');
		}

		&-results_list {
			background-color: $color-white;
		}

		&-details_map {
			padding-right: $gutter-global;
		}
	}
}


@include respond-to(not-desktop) {
	.b-store_locator {
		.b-store_result-item {
			background-color: $color-white;
		}
	}
}

@include respond-to(phone) {
	.b-store_locator {
		&-details_title {
			@include font(26px, false);
		}

		&-details_button {
			margin: 0 0 $gutter-global;
			padding: 10px;
			text-transform: uppercase;
			background-color: transparent;
			border: 1px solid $color-grey_light5;
				&.expanded {
					background-color: $color-grey_light5;
				}
		}

		&-details_info {
			.b-store-label {
				margin-bottom: 10px;
			}
		}
	}
}
