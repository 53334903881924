%bg-pp {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 125px auto;
	background-image: url(logo-pp.png);
}

%bg-world {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 65px auto;
	background-image: url(world.png);
}

%bg-philippplein {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 65px auto;
	background-image: url(philipp_plein.png);
}

%bg-360view {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 40px auto;
	background-image: url(360view.png);
}

%bg-infinite-loader {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 55px auto;
	background-image: url(logo-pp.png);
}