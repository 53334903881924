.b-address {
	&-form {
		.f-required {
			margin-bottom: 10px;
		}
		
		.f-field-button {
			margin: 0;
			@include font(0, 0);
			text-align: right;
		}
	}
	
	&-form_apply,
	&-form_cancel {
		margin-top: 10px;
	}
	
	&-form_cancel {
		margin-left: 20px;
	}
	
	&-form_delete {
		display: none;
	}
	
	&-form_title {
		@extend %dialog-title;
	}
	
	.b-account-primary_title {
		display: inline-block;
	}
	
	&-create.m-text {
		float: right;
		margin: 7px 0;
		@include icon(plus);
		text-align: center;
		text-transform: uppercase;
		text-decoration: none;
		
		&:before {
			@include font(9px, false);
			margin-bottom: 2px;
		}
		
		& + .b-address-create {
			display: none;
		}
	}
	
	&-list {
		@include flexbox();
		@include row-wrap();
		@include jc-space-between();
		clear: both;
	}
	
	&-tile {
		width: 49%;
		margin-bottom: 20px;
		padding: 25px 30px 25px 25px;
		background: $color-white;
		border: 1px solid $color-grey_light4;
		@include font(0,0);
		white-space: nowrap;
	}
	
	&-default {
		float: right;
		width: 30%;
		color: $color-grey;
		@include font(10px);
		text-transform: uppercase;
		white-space: normal;
	}
	
	&-miniaddress,
	&-actions {
		display: inline-block;
		vertical-align: bottom;
		white-space: normal;
	}
	
	&-miniaddress {
		width: 70%;
		padding-right: 10px;
		@include font($font_size-small);
	}
	
	&-actions {
		width: 30%;
		margin-bottom: 3px;
	}
	
	&-actions_item {
		margin-bottom: 10px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&-make_default,
	&-edit,
	&-delete {
		word-wrap: normal;
	}
	
	.mini-address {
		&-title,
		&-name,
		&-location {
			@include font($font_size-small, false);
			line-height: 18px;
		}
		
		&-title {
			margin-bottom: 15px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	
	@include respond-to(not-phone) {
		&-create:last-child {
			display: none;
		}
	}
	
	@include respond-to(tablet) {
		&-tile {
			margin-bottom: 15px;
		}
	}
	
	@include respond-to(phone) {
		&-form {
			padding: 0;
			
			.f-textinput,
			.f-textarea,
			.f-select {
				width: 100%;
				text-align: left;
			}
			
			.f-caption,
			.error {
				margin: 0;
			}
			
			.f-caption,
			.error {
				text-align: left;
			}
			
			.f-field-button {
				margin-left: 0;
				text-align: center;
			}
			
			.b-address-form_apply,
			.b-address-form_delete,
			.b-address-form_cancel {
				max-width: 320px;
				width: 100%;
				margin: 0 0 20px;
			}
		}
		
		&-tile {
			width: 100%;
			margin: 0 0 5px;
			padding: 30px 20px;
		}
		
		&-miniaddress {
			width: 60%;
		}
		
		&-default,
		&-actions {
			width: 40%;
			text-align: right;
		}

		&-actions .b-button {
			text-align: right;
		}
	}
}