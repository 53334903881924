@include cookies($color-grey2, $color-grey_light3, 80px, 160px);

.b-cookies_message {
	background-color: $color-black2;
}

.b-cookies_acceptance {
	@include respond-to(desktop) {
		padding: 0 $gutter-global;
	}
}
