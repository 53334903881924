@mixin pdp-product_360view(
	$image_ratio: percentage(768/603),
	$message_size: 13px,
	$message_bg: rgba(255, 255, 255, 0.8)
) {
	.b-360view {
		position: relative;
		
		&-container {
			position: relative;
			width: 100%;
			height: auto !important;
			overflow: hidden;
			
			// to prevent content jumping while loading image of product
			&:before {
				content: '';
				float: left;
				padding-bottom: $image_ratio;
			}
		}

		&-list,
		&-list img,
		&-spinner {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
		}
		
		&-list {
			display: none;
			opacity: 1 !important;
			cursor: url(../images/360cursor.cur), move; //for Edge
			cursor: url(../images/360cursor.cur) 40 8, move; //for normal browsers
		}
		
		&-list img {
			opacity: 0;
			-webkit-transform-style: flat;
			transform-style: flat;
			
			&.current-image {
				opacity: 1;
				z-index: $z-min;
			}
		}
		
		&-placeholder {
			pointer-events: none;
		}
		
		&-spinner {
			background: fade-out($color-white, 0.25);
		}
		
		&-progress {
			@include abs-center;
		}
		
		&-message {
			@include transition(opacity);
			position: absolute;
			top: 50%;
			left: 0; right: 0;
			z-index: $z-min;
			text-align: center;
			text-transform: uppercase;
			pointer-events: none;
			opacity: 1;
			
			margin-top: -($message_size/2 + 5px);
			@include font($message_size);
			line-height: $message_size;
			font-weight: 600;
			
			&.hidden {
				display: block;
				opacity: 0;
				@include transition(opacity, 1s);
			}
		}
		
		&-message_text {
			display: inline-block;
			vertical-align: top;
			padding: 10px;
			background-color: $message_bg;
			
			@include icon(arrow-left, before, false, 6px);
			@include icon(arrow-right, after, false, 6px);
			
			&:before,
			&:after {
				vertical-align: top;
				font-weight: 700;
				text-shadow: 1px 0 0 $color-black;
			}
		}
		
		&-zoom {
			position: absolute;
			top: 0; left: 0;
			bottom: 0; right: 0;
			z-index: -1;
			
			&.js-zoom-enabled {
				z-index: $z-min;
				cursor: url(../images/close.cur), pointer; //for Edge
				cursor: url(../images/close.cur) 12 12, pointer; //for normal browsers
			}
		}
		
		.loader-indicator {
			background-attachment: scroll;
		}
		
		.b-zoom-link {
			width: auto;
			height: auto;
			
			.dialog-360view_zoom & {
				display: none;
			}
		}
		
		> .b-zoom-link {
			&,
			.ie & {
				cursor: pointer;
			}
		}
	}

	.dialog-360view_zoom {
		.b-360view {
			max-width: 78vh;
			margin: 0 auto;
		}

		.ui-dialog-close_overlay {
			left: 50%;
			width: 78vh;
			margin-left: -39vh;
		}
	}
}
