.b-title_box {
	@include flexbox();
	@include flex-column();
	color: $color-black;
	text-align: center;

	&.m-bg {
		background-size: cover;
	}

	&-title {
		@include font(26px, 2px, $font-family_additional);
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 20px;
		padding: 10px 0;

		.product-view & {
			display: none;
			text-align: left;
			font-size: 1.5rem;
			order: 3;
		}

		@include respond-to(phone) {
			@include font(20px, false);
		}
	}

	&-subtitle {
		display: block;
		padding: 10px 0;
		border-bottom: 1px solid $color-black;
		@include order(1);
		@include respond-to(phone) {
			display: none;
		}

		a {
			display: inline-block;
			padding: 0 10px;
			color: $color-black;
			letter-spacing: $letter_spacing-medium;
			line-height: 1;
			text-transform: uppercase;
			text-decoration: none;

			& + a {
				border-left: 2px solid $color-grey;
			}
		}

		p {
			color: $color-grey_light2;
			margin-top: 20px;

			@include respond-to(not-desktop) {
				display: none;
			}
		}
	}

	.pt_cart & {
		&-title {
			@include respond-to(not-phone) {
				margin: 0 300px;
			}
		}
	}
}
