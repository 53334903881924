@mixin b-dropdown(
	$arrow-size: 5px
) {
	.b-dropdown {
		&-button {
			@include icon(arrow-down, after);
			
			&:before {
				margin-right: 5px;
			}
			
			&:after {
				margin-left: 5px;
				font-size: $arrow-size;
			}
			
			&.active:after {
				content: icon-char(arrow-up);
			}
		}
		
		&-link {
			&:before {
				margin-right: 10px;
			}
		
			&.flag-icon:before {
				@include respond-to(desktop) {
					content: none;
				}
			}
			
			> span {
				vertical-align: middle;
			}
		}
		
		&-text {
			pointer-events: none;
		}
	}
	
	@include respond-to(desktop) {
		.b-dropdown {
			min-width: 100%;
			margin-top: 1px;
			@include scrollbar;
			
			&-button {
				&:after {
					line-height: 1;
				}
			}
			
			&-list {
				max-height: 360px;
				margin: 15px;
				overflow: auto;
			}
			
			&-item {
				padding: 5px 0;
			}
			
			&-link {
				display: block;
				line-height: 1.3;
				text-decoration: none;
				
				&:hover {
					text-decoration: underline;
				}
				
				&:before {
					float: left;
				}
			
				> span {
					display: block;
					overflow: hidden;
				}
			}
		}
	}
	
	@include respond-to(not-desktop) {
		.b-dropdown {
			width: 100%;
			background-color: $color-black2;
			
			&-button {
				&:after {
					float: right;
				}
				
				> span {
					vertical-align: middle;
				}
			}
	
			&-language {
				text-align: center;
			}
			
			&-header {
				background-color: $color-grey_light2;
				font-size: 20px;
				line-height: $utility_menu-height_mobile;
				letter-spacing: 1px;
			}
			
			&-list {
				-webkit-overflow-scrolling: touch;
				overflow: auto;
				padding-bottom: 150px; //this padding helps to show last few countries if cokie acceptence or promo message is shown;
			}
			
			&-item {
				border-bottom: 1px solid $color-black3;
			}
			
			&-link {
				.b-dropdown & {
					@include mobile-nav_menu-link;
					text-transform: uppercase;
				}
			}
		}
		
		.b-header-countries_selector,
		.b-header-languages_selector {
			position: static;
			
			.b-utility_menu-popup {
				@include transition(all, 400ms);
				top: 100vh;
				
				&.active {
					top: 100%;
					max-height: 100vh;
				}
			}
		}
	}
	
	@include respond-to(tablet) {
		.b-dropdown {
			left: 0; right: auto;
			max-width: 48%;
			min-width: 0;
			
			&-header {
				padding-left: 20px;
				
				.b-utility_menu-item {
					float: right;
				}
				
				> span,
				.b-utility_menu-link {
					line-height: $navigation_item-height_mobile;
				}
				
				.b-utility_menu-link:before {
					font-size: 20px;
				}
			}
			
			&-list {
				@include calc(height, '100vh - #{$header-height_tablet + $navigation_item-height_mobile}');
			}
		}
	}
	
	@include respond-to(phone) {
		.b-dropdown {
			margin-top: -$utility_menu-height_mobile;
			
			&-header {
				> span {
					display: inline-block;
					text-align: center;
				}
			}
			
			&-list {
				@include calc(height, '100vh - #{$header-height_mobile}');
			}
		}
	}
}

@mixin b-dropdown-select() {
	.b-dropdown-select {
		position: relative;
		
		.b-dropdown {
			@include scrollbar($color-white);
			position: absolute;
			top: 100%; left: 0;
			max-width: 100%;
			margin-top: -1px;
			border: solid $color-black3;
			border-width: 0px 1px 1px;
			background-color: $color-white;
		}
		
		.b-dropdown-button {
			position: relative;
			z-index: $z-min;
			min-height: $input-height;
			border: 1px solid $color-black3;
			padding: 7px 24px 7px 12px;
			background-color: $color-white;
			line-height: $input-height / 2;
			
			&:after {
				@include abs-center(auto, $mx-translate-x: 0);
				right: 12px;
			}
			
			> span {
				vertical-align: middle;
			}
		}
		
		.b-dropdown-list {
			height: auto;
			max-height: 360px;
			margin: 15px;
			overflow: auto;
		}
		
		.b-dropdown-item {
			padding: 5px;
			border: 0;
			
			a {
				display: block;
				text-decoration: none;
				line-height: 1;
			}
		}
	}
	
	.f-select-countries_selector {
		text-align: center;
		text-align-last: center;
		letter-spacing: $letter_spacing;
		color: $color-text;
		
		.ie & {
			padding-left: 20%;
			padding-right: 20%;
		}
	}
	
	.b-dropdown-field {
		position: relative;
		background: $color-white;
		
		&:before {
			position: absolute;
			top: 50%; left: 12px;
			@include transform(translate(0, -50%));
		}
		
		.f-select {
			position: relative;
			display: inline-block;
			width: 100%;
			background-color: $color-none;
			text-align: left;
			text-align-last: left;
			padding-left: 40px;
		}
	}
}
