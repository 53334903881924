@mixin cookies(
	$color-text: $color-grey_light,
	$color-close_button: $color-grey_light2,
	$max-height: 60px,
	$max-height_mobile: 130px,
	$size-text: $font_size-small
) {
	#browser-check {
		display: none;
		
		.b-site_header & {
			display: block;
		}
	}

	.browser-error,
	.b-cookies_acceptance-message {
		@include font($size-text, false);
		text-align: center;
		padding: 5px 10px;
		color: $color-text;
	
		a {
			color: $color-text;
		}
	}

	.browser-error {
		background-color: $color-red;
		color: $color-white;
		
		&.b-cookies-error {
			background-color: $color-orange;
		}
	}

	.b-cookies_message {
		display: none;
		position: relative;
		max-height: $max-height;
		overflow: hidden;
		
		@include respond-to(phone) {
			max-height: $max-height_mobile;
		}
		
		&.js-showed {
			display: block;
		}
		
		&.js-closed {
			@include transition;
			max-height: 0;
		}
	}

	.b-cookies_acceptance {
		@include flex-row;
		@include jc-center;
		@include ai-center;
		
		&.js-showed {
			@include flexbox;
		}
		
		&-message {
			@include flex(1);
		}
		
		&-close {
			@include icon(close, after);
			padding: 5px;
			@include font($font_size-small, false);
			font-weight: bold;
			color: $color-close_button;
			text-transform: uppercase;
			text-decoration: none;
			white-space: nowrap;
			
			&:after {
				@include font(6px, false);
				line-height: $font_size-small;
				margin-left: 10px;
				
				@include respond-to(phone) {
					margin: 5px;
					@include font(8px, false);
				}
			}

			&:hover .b-cookies_acceptance-button {
				text-decoration: none;
			}
		
			@include respond-to(phone) {
				margin-left: -10px;
				
				> span {
					display: none;
				}
			}
		}

		&-button {
			text-decoration: underline;
		}
	}
}