@mixin toggle() {
	.toggle {
		@include toggle-icon;

		cursor: pointer;
		padding-right: 30px;
		position: relative;
		
		@include respond-to(desktop) {
			&-mobile {
				display: none;
			}
		}
		
		&-content {
			@include toggle-content;
		}
		
		&-content-mobile {
			@include respond-to(not-desktop) {
				@include toggle-content;
			}
		}
	}
}

@mixin toggle-icon($icon_close: arrow-down, $icon_open: arrow-up) {
	@include icon($icon_close, after);
	
	&.expanded:after {
		content: icon-char($icon_open);
	}
}

@mixin toggle-content {
	display: none;
	overflow: hidden;
	
	.expanded + & {
		display: block;
	}
}
	
@mixin toggle-tabs {
	@include flexbox();
	@include wrap();
	@include jc-center();
	overflow: hidden;
	text-align: center;
	
	.toggle {
		@include order(1);
		display: inline-block;
		vertical-align: top;
		
		&.expanded {
			pointer-events: none;
		}
		
		&:after {
			content: none;
		}
	}
	
	.toggle-content {
		@include order(2);
		float: right;
		width: 100%;
		text-align: left;
	}
}
		
@mixin toggle-panel($tab-padding: 25px) {
	padding: $tab-padding;
	
	.toggle {
		@include toggle-icon(plus, minus);
		padding: $tab-padding 2*$tab-padding $tab-padding $tab-padding;
		margin: -$tab-padding;
		
		&:after {
			@include abs-center($mx-left: auto, $mx-translate-x: 0);
			right: $tab-padding;
		}
	}
	
	.toggle-content {
		&:before {
			content: '';
			display: block;
			height: $tab-padding;
		}
	}
}
		
@mixin toggle-line {
	.toggle {
		&:after {
			@include abs-center($mx-left: auto, $mx-translate-x: 0);
			right: 0;
		}
	}
}
