.breadcrumb {
	@include b-breadcrumbs;
	@include order(2);
	background-color: $color-bg;
	padding: 8px $gutter-global;
	line-height: 14px;
	text-align: left;
	text-transform: uppercase;

	&-element {
		&:after {
			margin: 0 3px;
		}
	}

	&-element &-text {
		color: $color-black;
		font-weight: 600;
	}
	
	@include respond-to(phone) {
		min-height: auto;
		padding-bottom: 0;
	}
}
