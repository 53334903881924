@mixin social_sharing($size: 18px, $color: $color-text_light, $gutter: 5px) {
	.share-icon {
		margin-left: $gutter;
		vertical-align: middle;
		text-decoration: none;
			
		&:before {
			font-size: $size;
		}
	}
}

@mixin social_sharing_popup(
	$size-icon: 30px,
	$size-text: 12px
) {
	.dialog-social_share {
		max-width: 700px;
		text-align: center;
	}
	
	.b-social_sharing {
		&-list {
			margin: 0 -20px 15px;
			@include font(0, 0);
			
			.share-icon {
				display: inline-block;
				vertical-align: top;
				margin: 30px 10px 0;
				width: 100px;
			
				@include respond-to(phone) {
					width: 120px;
				}
				
				&:before {
					@extend %b-button;
					padding: 15px;
					border-radius: 100%;
					@include font($size-icon, false, "iconfont");
					font-weight: 400;
					line-height: 1;
				}
				
				&:hover:before {
					box-shadow: 0 0 0 4px $color-white, 0 0 0 5px $color-black;
				}
				
				> span {
					display: block;
					margin-top: 10px;
					@include font($size-text);
					text-transform: uppercase;
				}
			}
		}
	}
}
