.infinite-scroll {
	&-text {
		@include font(12px);
		position: relative;
		z-index: $z-footer + 1;
		margin-bottom: -27px;
		color: $color-grey;
		line-height: 2;
		text-transform: uppercase;
		
		&:after {
			content: '';
			display: block;
			height: 55px;
			width: 55px;
			@extend %bg-infinite-loader;
			margin: 0 auto;
			@include animation(loader 2s infinite ease-in-out);
		}
		
		.infinite-scroll-loading & {
			display: block;
			float: left;
			width: 100%;
		}
	}
	
	&-placeholder,
	&-top-placeholder {
		text-align: center;
		display: inline;
		
		&:last-child,
		&.infinite-scroll-loading {
			display: block;
		}
	}

	@include keyframes(loader) {
		50% { opacity: 0.4; }
	}
}

.b-load-more_products {
	@include font(11px, $letter-spacing, $font_family-additional);
	margin-bottom: 20px;
}
