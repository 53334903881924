@mixin search-form-input {
	width: 100%;
	height: $input-height;
	padding: 6px 30px 6px 0;
	border: solid $color-black3;
	border-width: 0 0 1px;
	outline: 0;
	background: none;
	font-size: 16px;
	letter-spacing: 1px;
	
	&.error {
		border-color: $color-red;
	}
}

@mixin search-form-button {
	padding: ($input-height - 27px) / 2 0;
	
	&:before {
		font-size: 25px;
		line-height: 1;
	}
}

@mixin search-form(
	$form-width: 360px,
	$button-icon: search
) {
	.b-search-form {
		form {
			display: inline-block;
			width: $form-width;
			max-width: 100%;
			text-align: left;
		}
		
		input {
			float: left;
			margin-right: -40px;
			@include search-form-input;
		}
		
		button {
			float: right;
			width: auto;
			border: 0;
			background: none;
			box-shadow: none;
			@include font(0, false);
			color: $color-text;
			@include icon($button-icon);
			@include search-form-button;
		}
		
		span.error {
			display: none !important;
		}
	}
}

@mixin search-suggestions(
	$suggestions-width: 900px,
	$products-width: 65%
) {
	#search-suggestions {
		position: absolute;
		top: 100%;
		left: 0; right: 0;
		z-index: $z-header;
		width: $suggestions-width;
		max-width: 100%;
		margin: 0 auto;
	}
	
	.b-suggestion {
		@include clearfix;
		padding: 35px 0 25px;
		border: 1px solid $color-black3;
		border-top: 0;
		background-color: fade-out($color-black, 0.1);
		text-align: center;
		
		&,
		a {
			color: $color-grey2;
		}
		
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		
		&-header {
			margin-bottom: 40px;
		}
		
		&-title {
			margin-bottom: 15px;
			@include font(15px, 1px);
			line-height: 1;
			text-transform: uppercase;
		}
		
		&-phrase {
			display: inline-block;
			
			a {
				float: left;
				color: $color-white;
			}
			
			&:before {
				content: '\2018';
				float: left;
			}
			
			&:after {
				content: '\2019\0020\003F';
			}
		}
		
		&-products {
			@include font(0, 0);
			text-align: left;
			
			@include respond-to(not-phone) {
				display: inline-block;
				vertical-align: top;
				width: $products-width;
			}
		}
		
		&-product {
			@include font;
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding: 0 20px 10px;
			line-height: 1;
			
			@include respond-to(phone) {
				width: 100%;
			}
			
			.product-link {
				text-decoration: none;
			}

			.product-name,
			.product-price {
				color: $color-white;
			}

			.product-image {
				float: left;
				width: 75px;
				margin-right: 10px;
				
				img {
					width: 100%;
				}
			}
			
			.product-details {
				overflow: hidden;
			}

			.product-price {
				margin-top: 10px;
				&_initial {
					color: $color-grey_light;
					text-decoration: line-through;
					padding-bottom: 5px;
				}
				
				&_promo {
					font-size: 15px;
					color: $color-red;
					padding-bottom: 5px;
					font-weight: 600;
				}
				
			}
		
			.product-promo {
				margin-top: 4px;
			}
			.callout-message {
				display: block;
				div, span {
					font-size: 11px !important;
					padding: 3px 5px !important;
					display: inline-block;
				}
			}
			
		}
		
		&-content {
			@include font(0, 0);
			text-align: left;
			
			@include respond-to(not-phone) {
				float: left;
				width: 100% - $products-width;
			}
		}
		
		&-group {
			padding: 0 20px 20px;
			@include font;
			line-height: 1;
		}
		
		&-subtitle {
			margin-bottom: 15px;
			text-transform: uppercase;
			color: $color-white;
		}
		
		&-item {
			margin-bottom: 10px;
		}
	}
}
