@include b-return_form;

.b-return {
	&-form {
		.b-cell-quantity {
			width: 12%;
		}
		
		.b-cell-price {
			width: 11%;
		}
		
		.b-cell-shipment {
			width: 30%;
		}
	}
}

.b-return_information {
	&-label {
		font-weight: 600;
	}
}
