@include b-back_to_top-button;

.b-back_to_top-button {
  border: 1px solid $color-white;
  background-color: $color-black;
  color: $color-white;

  &:hover {
    background-color: $color-black2;
  }

  &.js-showed {
    z-index: 2;
  }
}
