.b-gift_certificate {
	@include font(12px);
	line-height: 1.5;
	color: $color-grey;
	
	&-purchase {
		@include respond-to(not-phone) {
			@include flexbox;
			@include jc-space-around;
			margin-bottom: 40px;
		}
	}
	
	&-banner,
	&-info,
	&-form {
		max-width: 400px;
		
		@include respond-to(not-phone) {
			@include flex(1);
			padding: 0 20px;
		}
		
		@include respond-to(phone) {
			margin: 25px auto;
		}
	}
	
	&-block {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $color-grey_light2;
		
		.f-field-textarea {
			.char-count,
			.f-caption {
				text-align: right;
			}
		}
		
		.f-field-button {
			margin-bottom: 20px;
		}
		
		.f-field:last-child {
			margin-bottom: 0;
		}
	}
	
	&-title {
		@extend %heading-2;
		font-weight: 400;
		color: $color-black;
	}
	
	&-subtitle {
		margin-bottom: 10px;
		@include font(13px);
		font-weight: 600;
		color: $color-black;
		text-transform: uppercase;
	}
	
	&-value {
		color: $color-grey;
		text-transform: none;
	}
	
	&-style {
		@include font(0, 0);
		
		.f-field-radio {
			margin: -10px -25px 10px 0;
		}
		
		.f-radio-group {
			display: inline-block;
			vertical-align: top;
			margin: 10px 25px 0 0;
		}
		
		.f-label {
			width: 70px;
			height: 40px;
			margin: 0;
			border: 3px solid $color-none;
			overflow: hidden;
			
			&:before {
				display: none;
			}
		
			> .f-label-value {
				display: none;
			}
			
			.f-label-image {
				max-width: none;
			}
		}
		
		.f-radio:checked ~ .f-label {
			border-color: $color-white;
			outline: 1px solid $color-grey_light;
		}
	}
	
	&-amount {
		.f-textinput {
			max-width: 120px;
		}
		
		.f-required-indicator {
			display: none;
		}
	}
	
	&-amount_options {
		margin: -10px -25px 10px 0;
		
		> .f-label {
			display: none;
		}
		
		.f-radio-group {
			display: inline-block;
			vertical-align: top;
			min-width: 70px;
			margin: 10px 25px 0 0;
			text-align: center;
				
			@include respond-to(phone) {
				margin-right: 10px;
			}
			
			.f-label {
				display: inline-block;
				vertical-align: top;
				margin: 0;
				padding: 5px 0;
				border-bottom: 1px solid $color-none;
				@include font(13px, false);
				color: $color-grey_light;
				
				&:before {
					content: none;
				}
			}
			
			.f-radio:checked ~ .f-label {
				border-color: $color-black;
				color: $color-black;
			}
			
			.f-label-value {
				display: block;
			}
		}
	}
	
	&-check {
		.f-label {
			display: none;
		}
	}
	
	&-image {
		border-radius: 7px;
	}
	
	&-button {
		width: 100%;
	}
	
	&-info_link {
		text-decoration: none;
		text-transform: uppercase;
		color: $color-grey;
		
		&:hover {
			color: $color-black;
		}
	}
	
	.dialog-required {
		float: right;
		width: auto;
		
		+ .f-field > .f-label {
			display: inline-block;
		}
	}
}
