.b-sitemap {
	&-title {
		@include font($font_size-h1, 2.5px, $font_family-additional);
		color: $color-text_light;
	}
	
	&-subtitle { 
		@include font(16px, $letter_spacing-medium);
		font-weight: 600;
		text-transform: uppercase;
		
		& > a {
			text-decoration: none;
		}
	}

	&-subtitle_2 {
		font-weight: 600;
		text-transform: uppercase;
	}
	
	&-list {
		margin: 5px 0 10px 15px;
		
		& > li {
			margin-bottom: 5px;
		}
	}
}