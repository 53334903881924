@include b-pagination;

.b-pagination {
	&-wrapper {
		background-color: $color-account-block;
		text-align: center;
	}
	
	&-list {
		margin: 20px 0;
		padding: 5px;
	}
	
	&-item {
		line-height: 15px;
	}

	&-current,
	&-link {
		min-width: 20px;
		padding: 5px;
		border: 1px solid $color-black3;
	}

	&-current,
	&-link:hover {
		color: $color-white;
		background-color: $color-black3;
	}

	&-link:before {
		@include font(11px, false);
	}
}

.items-per-page {
	#grid-paging-header {
		height: 27px;
		padding: 0 25px 0 5px;
		border: 1px solid $color-black3;
		background-color: $color-none;
	}
}
