@mixin search-form-button {
    padding: 8px 0 0;
    color: $color-black;

    &:before {
        @include font(20px, false);
        line-height: 1;
    }
}

@include search-form(
    $button-icon: magnifier
);

@include search-suggestions(100%);

.b-header-search {
    text-align: center;

    .b-utility_popup-close {
        opacity: 0;
    }

    .b-utility_input_clear {
        color: #D9D9D9;
        text-align: center;
        @include font(15px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 29px;
        -webkit-tap-highlight-color: transparent;
        
        &.active {
            color: #000;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.b-search-form {
    .input-text {
        @include font(15px, false, $font_family);
        color: $color-black;
        border-color: $color-black;
    }

    input {
        border-color: #E2E2E2;
    }

    button:before {
        color: #E2E2E2;
    }

    @include placeholder {
        color: $color-black;
    }
}

#search-suggestions {
    padding-top: 20px;
    background-color: $color-white;

    @include respond-to(desktop) {
        top: auto;
        background-color: $color-none;
        padding-top: 0;
    }
}

.b-suggestion {
    max-width: 960px;
    margin: 0 auto;
    border: 0;
    color: $color-black;
    background-color: $color-none;

    @include respond-to(desktop) {
        padding-top: 30px;
    }

    @include respond-to(not-phone) {
        padding-bottom: 120px;
    }

    @include respond-to(not-desktop) {
        padding-top: 0;
    }

    &-content {
        @include respond-to(not-phone) {
            float: right;
        }
    }

    &-header {
        margin-bottom: 55px;

        .b-suggestion-title {
            @extend %heading-2;
        }

        a {
            font-weight: 700;
            color: $color-black;
        }
    }

    &-title {
        + a {
            @include font(15px);
            text-transform: uppercase;
        }
    }

    &-product {
      .product-name,
      .product-price {
          color: $color-black;
      }

      .product-price {
          &_initial {
              color: $color-black;
              text-decoration: line-through;
              padding-bottom: 4px;
          }

          &_promo {
              color: $color-red;
              padding-bottom: 4px;
              font-weight: 600;
          }
      }

      .product-promo {
          margin-top: 4px;
      }
      .callout-message {
          display: block;
          div, span {
              font-size: 11px !important;
              padding: 3px 5px !important;
              display: inline-block;
          }
      }
    }

    &-subtitle {
        @include font(14px);
        font-weight: 600;
    }

    &-item {
        .hit {
            color: $color-black;
            font-weight: 600;
        }
    }

    &-phrase.m-hide-q:after {
        content: '\2019';
    }
}

.b-header-search.b-utility_popup-item {
    @include respond-to(phone) {
        background-color: $color-white;
        border-bottom: 1px solid $color-black;
    }
}
