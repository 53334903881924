.b-wishlist {
	&-search {
		@include clearfix();
		
		margin-bottom: 25px;

		.wishlist-search {
			padding: 30px 20px;
			border: 1px solid $color-grey_light3;
		}

		.f-field,
		.form-row-button {
			float: left;
			width: percentage(1 / 4);
		}

		.f-field + .f-field,
		.form-row-button {
			padding-left: 20px;
		}

		.f-textinput,
		button {
			width: 100%;
		}

		.form-row-button {
			margin: 19px 0 0;
		}

		@include respond-to(phone) {
			.f-field,
			.form-row-button {
				width: 100%;
				padding: 0;
			}

			.f-field + .f-field {
				padding: 0;
			}

			.form-row-button button {
				display: block;
				width: 100%;
			}
		}
	}

	&-search_item {
		font-weight: 700;
	}

	&-search_result {
		.b-wishlist-list_label,
		.b-wishlist-item_details {
			padding: 25px 20px;
		}

		.b-wishlist-item_details.view {
			text-align: right;
		}

		@include respond-to(phone) {
			.b-wishlist-list_heading {
				display: none;
			}

			.b-wishlist-item_label {
				float: left;
				width: 100px;
				padding-right: 10px;
			}

			.b-wishlist-item_value {
				float: right;
				width: calc(100% - 100px);
			}

			.b-wishlist-item_details {
				float: left;
				width: 100%;
				padding: 10px 0;
			}

			.button {
				width: 100%;
				text-align: center;
			}
		}
	}
}
