.b-pdp-product_set {
    &.b-pdp-product_view {
		padding-bottom: 20px;
	}

	&-list {
		margin-bottom: 20px;
		text-align: left;
	}
	
	&-item {
		padding: 15px 0px;
	}

    &-price {
        margin-bottom: 15px;
    }
	
	&-image {
		float: left;
		width: 70px;
		margin: 0 10px 10px 0;
	}
	
	&-details {
		.b-pdp-product_bundle & {
			overflow: hidden;
		}
		
		.b-pdp-product_name {
			@include font($font_size-h1, false, $font_family-additional);
			
			a {
				text-decoration: none;
			}
		}
		
		.b-pdp-product_number,
		.b-pdp-pricing,
		.attribute {
			clear: none;
			margin: 5px 0 0;
		}
		
		.b-pdp-product_quantity {
			display: none;
		}
		
		.product-options {
			margin-bottom: 0;
		}
		
		.b-pdp-variations {
			clear: both;
		}
	}
	
	&-info {
		overflow: hidden;
	}
}
