@mixin personal_info_form(
	$form_width: 740px,
	$column_gutter: 20px
) {
	.b-account-personal_info {
		.f-create_account {
			@include respond-to(desktop) {
				width: $form_width;
				max-width: 100%;
			}
		
			+ .b-account-primary_title {
				margin-top: 40px;
			}
		}
		
		.f-personal_info {
			@include respond-to(not-phone) {
				max-width: 50%;
				padding-right: $column_gutter / 2;
				
				.f-textinput {
					width: 100%;
				}
			}
		
			@include respond-to(desktop) {
				width: $form_width / 2;
			}
		}
		
		.f-field-button {
			@include respond-to(phone) {
				text-align: center;
			}
		}
	}
}
