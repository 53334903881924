@mixin b-wishlist {
	.b-wishlist {
		&-default_label {
			font-weight: 400;
			text-transform: lowercase;
	
			@include respond-to(phone) {
				display: block;
			}
		}
	
		&-create {
			float: right;
	
			@include respond-to(phone) {
				width: 100%;
			}
		}
	
		.b-cell-details {
			width: 25%;
		}
			
		.b-cell-actions {
			width: 40%;
		}
		
		&-item_actions {
			line-height: 0;
			text-align: center;
			
			&-label {
				display: inline-block;
				vertical-align: top;
				width: 140px;
				text-align: center;
				line-height: normal;
			}
			
			&-fake_button {
				margin: -50px 0;
				opacity: 0;
				pointer-events: none;
			}
		}
		
		&-item_form {
			text-align: center;
		}
		
		&-item_qty {
			margin: 5px 10px;
	
			.label {
				display: none;
			}
		}
	
		&-actions {
			margin-top: 20px;
			text-align: right;
		}
	
		@include respond-to(not-phone) {
			&-item_form {
				position: relative;
				padding-right: 30px;
			}
			
			&-item_actions {
				&-label {
					margin-right: 20px;
				}
			}
			
			&-item_button,
			&-item_qty {
				display: inline-block;
				vertical-align: middle;
			}
		
			&-item_button {
				margin: 5px 0;
			}
			
			&-make_default {
				margin-right: 15px;
			}
		}
	
		@include respond-to(phone) {
			&-item_qty {
				text-align: center;
			}
		
			&-make_default {
				margin-bottom: 20px;
			}
			
			.add-to-cart,
			&-delete,
			&-make_default,
			&-move_button {
				width: 100%;
				padding-right: 20px;
				padding-left: 20px;
			}
		}
	}
}

@mixin b-wishlist_remove(
	$icon: false
) {
	.b-wishlist {
		&-item_remove {
			position: absolute;
			top: 20px;
			right: 0;
			background: $color-none;
			
			@include font(0,0);
			color: $color-text;
	
			@include respond-to(not-phone) {
				top: 50%;
				margin-top: -10px;
			}
			
			&.b-button {
				height: auto;
				padding: 0;
				border: 0;
				background: $color-none;
			}
			
			@if $icon {
				&:before {
					content: icon-char($icon);
				}
			}
		}
	}
}

@mixin b-wishlist_move {
	.b-wishlist {
		&-move {
			margin-bottom: 20px;
		}
	
		&-move_row {
			border-bottom: 0;
			
			td {
				padding: 0;
			}
		}
	
		&-move_label,
		&-move_options,
		&-move_dropdown {
			display: inline-block;
			vertical-align: middle;
			margin-right: 15px;
		}
	
		&-move_dropdown {
			margin-right: 20px;
	
			@include respond-to(not-phone) {
				min-width: 200px;
				width: auto;
			}
		}
		
		&-move_button {
			@include respond-to(phone) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
}

@mixin b-wishlist_popup {
	.b-create_wishlist {
		@include respond-to(not-phone) {
			.f-field {
				max-width: 350px;
				margin-left: auto;
				margin-right: auto;
			}
			
			.f-field-button {
				max-width: none;
				margin-bottom: 0;
				text-align: center;
			}
		
			&-new,
			&-close {
				margin: 0 5px;
				vertical-align: top;
			}
		}
	
		@include respond-to(phone) {
			.f-field-button {
				margin-bottom: 0;
			}
			
			&-new,
			&-close {
				width: 100%;
			}
	
			&-new {
				margin-bottom: 20px;
			}
		}
	}
}
