.pt_product-details {
	.b-title_box {
		display: block;
		background: $color-black;
		padding: 0;
		margin: 0;
		
		.b-content {
			@include respond-to(phone) {
				display: none;
			}
		}

	}
	
	.b-pdp-navigation_wrapper {
		float: right;
	}
}

.pdp-main {
	position: relative;
	margin-bottom: $gutter-pdp;
}