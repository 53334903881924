// Header
// 'b-' prefixes only

.b-site_header {
	width: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	transition: background-color 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;

	&-wrapper {
		position: relative;
		z-index: $z-header;
	}

	&-placeholder {
		background-color: transparent;
		transition: background 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;
	}

	.js-header_fixed & {
		position: fixed;
		top: 0; right: 0; left: 0;

		&-wrapper {
			position: fixed;
			z-index: $z-header;
		}
	}

	@include respond-to(not-desktop) {
		@include flexbox();
		@include flex-column();

		&:before {
			content: none;
		}

		.js-header_minimized & {
			overflow: hidden;
		}
	}
}

.b-header-logo_wrapper {
	position: relative;

	.b-header-top_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.b-utility_menu {
			width: 20%;

			@include respond-to(tablet) {
				width: 25%;
			}

			@include respond-to(phone) {
				width: max-content;
			}
		}

		.primary_logo {
			flex: 1;

			@include respond-to(phone) {
				margin-right: 0;
				padding-right: 10px;
			}
		}
	}

	@include respond-to(desktop) {
		max-width: 1440px;
		margin: 0 auto;
	}

	@include respond-to(phone) {
		@include flexbox;
		@include flex-column;
	}

	.primary_logo {
		a {
			@include respond-to(desktop) {
				&:before {
					background-size: 90px auto;
				}
			}
		}
	}


}

.primary_logo {
	padding: 20px;
	@include flexbox();
	@include jc-center();

	a {
		// inline-block should be added to this link for all browsers and devices for correct minimizing of logo
		display: inline-block;

		// but not for IE
		.ie & {
			display: inline;
		}

		&:before {
			@extend %bg-philippplein;
			content: none;
			background-position: 30px 50%;
			background-size: 90px auto;
			position: absolute;
			top: 0; left: 0;
			height: 100%;
			width: $logo-width;
			max-height: $header-height;
		}
	}

	img {
		width: 375px;
		height: 100%;
		display: flex;
		margin: auto;

		transition: width 0.8s cubic-bezier(0.5, 0, 0, 1) 0s;
		will-change: width;

		@include respond-to(desktop) {
			width: 531px;
		}
	}

	@include respond-to(desktop) {
		margin: 0 auto;
	}

	@include respond-to(not-desktop) {
		background: none;

		a {
			&:before {
				content: none;
			}
		}
	}

	@include respond-to(tablet) {
		z-index: $z-min;
	}

	@include respond-to(phone) {
		@include order(-1);
		@include jc-start();
	}
}

.b-header-promotion {
	display: block;
	background-color: $color-white;
	border-bottom: 1px solid $color-black;

	@include respond-to(not-desktop) {
		@include order(3);
	}
}

.b-promotion {
	padding: 7px 60px 8px;
	color: $color-black;
	@include font($font_size-xs, $letter_spacing-medium);
	font-weight: 700;
	line-height: 15px;
	text-transform: uppercase;
	background-color: $color-white;

	@include respond-to(phone) {
		padding: 5px 30px;
	}

	&-close {
		display: none;
		position: absolute;
		top: 7px; right: 40px;
		color: $color-grey2;

		@include respond-to(phone) {
			top: 4px; right: 15px;

			&:before {
				@include font($font_size-small, false);
			}
		}
	}
}

body:not(.js-header_fixed) {
	.is_stuck {
		position: static !important;

		+ div {
			display: none !important;
		}
	}
}

@include respond-to(not-desktop) {
	.js-keyboard_opened {
		.b-site_header-wrapper,
		.b-site_header {
			position: relative;
		}
	}
}
