// Buttons

@mixin b-button-disabled($bg_color) {
	color: $color-white;
	background: $color-button_disabled;
	border-color: $color-button_disabled;
}

.button,
.b-button,
%b-button {
	@include b-button(
		$font-small: 600 13px / 17px $font_family, 
		$font-large: 600 15px / 18px $font_family
	);
	@include b-button-states(
		$bg_color: $color-black,
		$border_color: $color-black,
		$text_color: $color-white,
		
		$bg_color-hover: $color-white,
		$text_color-hover: $color-black
	);
}

.b-button.m-grey,
%b-button-grey {
	@include b-button-states(
		$bg_color: $color-grey,
		$border_color: $color-grey,
		$text_color: $color-white,
		
		$bg_color-hover: $color-white,
		$text_color-hover: $color-grey
	);
}

.b-button.m-light_grey,
%b-button-light_grey {
	@include b-button-states(
		$bg_color: $color-grey_light3,
		$border_color: $color-grey,
		$text_color: $color-black3,
		
		$bg_color-hover: $color-grey,
		$text_color-hover: $color-white
	);
}

.b-button.m-white,
%b-button-white {
	@include b-button-states(
		$bg_color: $color-button_light,
		$border_color: $color-grey_light2,
		$text_color: $color-black3,
		
		$bg_color-hover: $color-grey_light2
	);
	
	&:hover,
	&:active {
		&:before {
			color: $color-button_icon;
		}
	}
}

.b-button.m-transparent,
%b-button-transparent {
	@include b-button-states(
		$bg_color: $color-none,
		$border_color: $color-black3,
		$text_color: $color-black3,
		
		$bg_color-hover: $color-black3,
		$text_color-hover: $color-grey_light4
	);
}

.b-button.m-white_transparent,
%b-button-white_transparent {
	@include b-button-states(
		$bg_color: $color-none,
		$border_color: $color-grey_light4,
		$text_color: $color-grey_light4,
		
		$bg_color-hover: $color-grey_light4,
		$text_color-hover: $color-black3
	);
}

.button-text,
.b-button.m-text,
%b-button-text {
	@include b-button-text($color-text_button, 12px / 1.2 $font_family);

	&:before {
		color: $color-grey2;
	}
}
