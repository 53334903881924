.b-wishlist {
	&-address_row {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $color-grey_light3;
	}

	&-address_label {
		text-transform: uppercase;
	}

	&-address_select {
		min-width: 50%;
	}
}
