.availability-instore,
.availability-web {
	@include font($font_size-small, false);
	line-height: 1.2;
	
	.label,
	.value,
	.availability-results {
		display: inline-block;
		vertical-align: top;
	}
	
	.label:after {
		content: ':';
	}
	
	.availability-novariation {
		display: none;
	}
}

.availability-msg,
.product-availability-list {
	@include font($font_size-small, false);
	line-height: 1.2;
	text-transform: uppercase;
	.additional-availability-msg {
		margin-top: 8px;
		text-transform: none;
		font-weight: normal;
		color: $color-grey-light;
		&.not-available {
			color: $color-red;
		}
	}
}

%in_stock_message {
	@include font($font_size-small, false);
	text-transform: none;
	color: $color-grey;
}

.product-availability-list {
	> span {
		display: block;
	}

	.is-in-stock {
		color: $color-green;
	}

	.on-order {
		color: $color-orange;
		
		+ .on-order {
			@extend %in_stock_message;
		}
	}
}

.last-left,
.not-available {
	color: $color-red;
}
.avail-date {
	color: $color-grey;
	text-transform: none;
	font-weight: normal;
}

.availability-msg {
	li {
		margin-bottom: 3px;
	}
	.in-stock-msg {
		color: $color-green;
	}

	.low-stock-msg,
	.backorder-msg,
	.preorder-msg {
		color: $color-orange;
	}
	
	.in-stock-date-msg {
		@extend %in_stock_message;
	}
}
