.b-text,
%b-text {
	@include font($font_size-small, false, $font_family-additional);
	
	h1,
	h2,
	h3,
	h4 {
		&:first-child {
			margin-top: 0;
		}
	}
	
	h3 {
		@include font(22px, false, $font_family);
		font-weight: 600;
		text-transform: uppercase;
	}
	
	h4 {
		@include font(14px, false, $font_family);
		font-weight: 600;
		text-transform: uppercase;
		margin: 25px 0;
	}
	
	li {
		list-style: disc inside;
	}
	
	p {
		line-height: 	1.5;
	}
}