iframe {
	display: block;

	.dialog-customer_services & {
		min-height: 620px;

		@include respond-to(not-phone) {
			min-width: $width-tablet;
		}

		@include respond-to(phone) {
			min-height: 1210px;
		}
	}

	.dialog-newsletter_subscribe & {
		@include respond-to(not-phone) {
			min-width: $width-tablet;
			min-height: 435px;
		}

		@include respond-to(phone) {
			height: 740px;
		}
	}

	.dialog-back_in_stock & {
		min-height: 250px;
	}

	.dialog-request_product_info & {
		min-height: 475px;

		@include respond-to(phone) {
			min-height: 535px;
		}
	}
}
