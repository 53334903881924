@mixin b-order_details(
	$cell-width: 17%
) {
	.b-order {
		&-actions {
			text-align: right;

			@include respond-to(phone) {
				> .b-button {
					width: 100%;
				}
			}
		}
	}

	.b-order_shipment {
		overflow: hidden;

		&-wrapper {
			overflow: hidden;
		}

		&-items {
			@media only screen and (min-width: $width-tablet) and (max-width: $width-desktop_small) {
				float: left;
				width: 75%;
				margin-right: -1px;

				.b-order_table-header-cell {
					@include text-ellipsis;
				}
			}

			.b-cell-quantity,
			.b-cell-price {
				width: $cell-width;
			}
		}

		&-info {
			overflow: hidden;

			@media only screen and (min-width: $width-tablet) and (max-width: $width-desktop_small) {
				.b-order_table-header-cell:first-child,
				.b-order_table-cell:first-child {
					padding-left: 20px;
				}
			}
		}

		&-details {
			.value {
				display: inline-block;
				max-width: 100%;
				font-weight: 600;

				&.h-email_address {
					@include text-ellipsis;
					cursor: default;
				}
			}
		}

		&-status,
		&-method {
			margin-top: 10px;
		}

		&-gift_message {
			clear: both;

			.b-order_table-label {
				width: 100%;
			}
		}
	}
}
