.price-unadjusted,
.product-standard-price,
.price-standard {
  @include font(15px, false, $font_family);
  color: $color-grey2;
  text-decoration: line-through;
  white-space: nowrap;
}

.price-adjusted-total,
.price-total,
.price-sales {
  @include font(15px, false, $font_family);
  white-space: nowrap;
  font-weight: 900;
}

.promotion .callout-message,
.b-order_product-promo {
  color: $color-red;
}

.b-order_product-promo {
  display: none;
}

.product-price .price-crypto {
  display: block;
  clear: left;
  color: #747474;
  font-weight: bold;
  text-transform: none;
  font-size: 11px;
}

.promotion-callout {
  margin-bottom: 2px;
  max-width: max-content;
  display: block;
}
