.b-pdp-images {
	&-indicator {
		@include flexbox;
		@include flex-column;
		@include jc-end;
		position: absolute;
		top: 0;
		width: auto;
		padding: 50px 0;
		
		@include respond-to(not-desktop) {
			display: none;
		}
		
		.js-header_fixed &.is_stuck {
			@include transition(none);
		}
	}

	&-indicator_item {
		display: block;
		width: 12px;
		height: 12px;
		margin: 5px auto;
		background: $color-grey_light3;
		border: 1px solid $color-grey_light;
		border-radius: 50%;
		cursor: pointer;

		&:hover,
		&.active {
			background: $color-black;
			border-color: $color-black;
		}
	}
}