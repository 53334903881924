@include base;

body {
	background-color: $color-bg;
	color: $color-black;
}
	
.b-secondary-navigation {
	@include respond-to(not-desktop) {
		margin: 0 -#{$gutter-global} $gutter-global;
	}
}

.p-iframe-body {
	background-color: $color-none;
}
