@mixin b-order_product{
	.b-order_product {
		&-name {
			margin-bottom: 10px;
			
			@include respond-to(phone) {
				padding-right: 25px;
			}
		}
	
		&-link {
			text-decoration: none;
	
			&:hover {
				text-decoration: underline;
			}
		}
	
		&-promo {
			margin-bottom: 5px;
			color: $color-red;
		}
	
		&-sku {
			.label {
				display: none;
			}
		}
	
		&-attribute {
			text-transform: none;
			
			> .label:after {
				content: ':';
			}
		}
	
		&-price {
			display: none;
		}
	
		&-actions_link,
		&-edit_link {
			margin-top: 10px;
		}
	}
}
