.b-account {
	&-menu {
		@include flexbox();
		@include row-wrap();
		margin: 0 (-$gutter-global / 2);
	}
	
	&-menu_item {
		@include flexbox();
		min-height: 200px;
		width: percentage(1 /3);
		margin-bottom: $gutter-global;
		padding: 0 $gutter-global / 2;
		text-align: center;
		
		@include respond-to(phone) {
			width: percentage(1 / 2);
		}
	}
	
	&-menu_link {
		@include transition($transition-duration: 0.5s, $transition-timing-function: ease);
		@include flexbox();
		@include flex-column();
		@include jc-center();
		width: 100%;
		padding: 20px;
		background: $color-white;
		text-decoration: none;
		
		&:hover {
			background: $color-grey_light3;
		}
		
		&:hover .b-account-menu_title,
		&:hover .b-account-menu_info {
			color: $color-black;
		}
		
		@include respond-to(phone) {
			padding: 15px 5px;
		}
	}
	
	&-menu_title {
		@include font(14px, $letter_spacing-medium);
		color: $color-text_light;
		font-weight: 600;
		text-transform: uppercase;
		
		&:before {
			display: block;
			margin-bottom: 10px;
			@include font(25px, false);
		}
		
		@include respond-to(phone) {
			.h-visible_phone {
				display: none;
			}
		}
	}
	
	&-menu_info {
		@include font(11px, $letter_spacing);
		color: $color-text_light;
		margin-top: 10px;
		
		@include respond-to(phone) {
			display: none;
		}
	}
}