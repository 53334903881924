@mixin minicart(
	$minicart-width: 270px,
	$minicart-height: 1000px,
	$minicart-products-height: 420px
) {
	.b-header-minicart {
		position: relative;
		
		@include respond-to(desktop) {
			background-color: $color-minicart;
		}
	}
	
	.minicart-quantity {
		&:before {
			content: '(';
		}
		
		&:after {
			content: ')';
		}
	}
	
	.b-mini_cart {
		&-link {
			.active & {
				@extend %utility_menu-link-active;
				
				@include respond-to(not-desktop) {
					.minicart-quantity {
						display: none;
					}
				}
			}
			
			@include respond-to(not-desktop) {
				.minicart-quantity {
					display: inline-block;
					max-width: 40px;
				}
			}
		}
		
		&-empty {
			position: relative;
			z-index: $z-min;
			
			@include respond-to(not-desktop) {
				.minicart-quantity {
					display: none;
				}
			}
		}
		
		&-content {
			@extend %utility_menu-popup;
			@include scrollbar;
			@include transition(max-height);
			min-width: $minicart-width;
			max-height: 0;
			overflow: hidden;
			
			@include respond-to(tablet) {
				min-width: 320px;
			}
			
			@include respond-to(phone) {
				min-width: 100vw;
			}

			.active > & {
				max-height: $minicart-height; 

				.ios & {
					@include transform(translate3d(0,0,0)); // fixes not fully loaded styles for minicart
				}
			}
		}
		
		&-products {
			overflow: auto;
			max-height: $minicart-products-height;
		}
		
		&-product {
			overflow: hidden;
		}
		
		&-image {
			float: left;
			
			> img {
				width: 100%;
			}
		}
		
		&-name > a {
			text-decoration: none;
		}

		&-product .product-availability-list,
		&-attributes {
			margin: 10px 0 0 85px;
		}

		&-pricing {
			margin-top: 10px;

			.value {
				&:after {
					content: ' x';
				}
			}

			margin-left: 85px;
		}

		&-link-cart {
			width: 100%;
			margin-top: 10px;
			padding: 8px 20px;
		}
		
		&-link-checkout {
			display: none;
		}

		&-subtotals-adjusted,
		&-subtotals-unadjusted {
			.label {
				text-transform: uppercase;
			}
		}

		&-subtotals-adjusted {
			@include font(16px);

			margin-top: 10px;

			.ie & {
				max-width: 170px; // prevent minicart expanding
			}
		}

		&-totals_row {
			@include flexbox();
			@include jc-space-between();

			margin-bottom: 5px;

			.label {
				text-align: left;
			}

			.value {
				padding-left: 5px;
				margin-left: auto;
				text-align: right;
				white-space: nowrap;
			}
		}

		&-price_wrap {
			position: relative;
			margin-left: 15px;
			line-height: initial;

			.value {
				position: absolute;
				bottom: 0;
				right: 100%;
				margin-right: 12px;
				white-space: nowrap;
				letter-spacing: 0;
			}
		}

		&-adjustments {
			margin-top: 10px;

			.promo-adjustment:last-of-type:not(:first-of-type) {
				margin-bottom: 10px;
			}
		}

		&-adjustments,
		&-price {
			text-align: right;
		}

		&-price-adjusted > span,
		&-price-unadjusted > span {
			white-space: nowrap;
		}

		&-price-adjusted > span {
			margin-left: 2px;

		}

		&-price-unadjusted > span {
			text-decoration: line-through;
		}
	}
}

@mixin minicart-quantity_icon(
	$font-size: 12px,
	$icon-size: 30px
) {
	.minicart-quantity {
		@include respond-to(not-desktop) {
			position: absolute;
			bottom: 0; left: 0; right: 0;
			width: $icon-size / 1.5;
			margin-left: auto;
			margin-right: auto;
			@include font($font-size, false);
			line-height: 1;
			text-align: center;
			@include text-ellipsis;
			
			&.minicart-quantity {
				display: block;
				
				.b-mini_cart-empty &,
				.active & {
					display: none;
				}
			}
			
			&:before,
			&:after {
				content: none;
			}
		}
		
		@include respond-to(tablet) {
			margin-bottom: ($utility_menu-height_tablet - $font-size - 4) / 2;
		}
		
		@include respond-to(phone) {
			margin-bottom: ($utility_menu-height_mobile - $font-size - 4) / 2;
		}
	}
}
