@mixin reset_password_form($form-width: 600px) {
	.b-reset_password {
		max-width: $form-width;
		margin: 0 auto;
		text-align: center;
	
		&-details {
			margin-bottom: 20px;
		}
	
		&-error {
			color: $color-red;
			@include font($font_size, false);
		}
		
		.f-field-text,
		.f-field-password {
			text-align: left;
		}
		
		.f-field-button {
			margin-bottom: 0;
		}
		
		.f-textinput {
			width: 100%;
		}
		
		.b-button {
			vertical-align: top;
			margin: 0 5px;
			
			@include respond-to(phone) {
				width: 100%;
				margin: 0 0 10px;
			}
		}
	}
}
