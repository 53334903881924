@include checkout-place_order-totals;
@include checkout-place_order-table;

.b-checkout-place_order {
	&-totals,
	.order-totals-table {
		.order-shipping-payment-method-fee,
		.order-shipping,
		.order-sales-tax {
			@include font($font_size-small, false);
		}
	}

	&-totals {
		margin-bottom: 5px;
		padding: 20px;
		background-color: $color-white;
	}
}