@mixin checkout-place_order-totals (
	$order-totals-width: 360px,
	$button-desktop-width: 100%
) {
	.b-checkout-place_order {
		&-footer {
			@include respond-to(desktop) {
				float: right;
				width: $order-totals-width;
				max-width: 100%;
			}
		}

		&-totals {
			@include respond-to(not-desktop) {
				display: none;
			}
		}

		@if $button-desktop-width {
			&-button {
				@include respond-to(desktop) {
					width: $button-desktop-width;
				}
			}
		}

		.b-checkout-actions {
			text-align: center;
		}
		.b-checkout-consent {
			padding: 15px 0;
			input {
				float: left;
			}
			p {
				margin-left: 30px;
				color: $color-grey;
				text-align: left;
				@include font(13px);
				a {
					color: $color-grey;
				}
			}
		}
		.b-checkout-secondary &-button {
			display: none;
		}
	}
}

@mixin checkout-place_order-table {
	.b-summary-table {
		margin-bottom: 20px;
		
		.b-cart-cell_delivery_options {
			display: none;
		}
		

		@include respond-to(not-phone) {
			td.b-cart-cell_details {
				width: 30%;
			}

			.b-cart-cell_quantity {
				width: 15%;
			}
			
			.b-cart-cell_price,
			.b-cart-cell_total {
				width: 20%;
			}
		}

		@include respond-to(tablet) {
			.b-cart-cell_image {
				display: block;
			}

			.b-cart-cell_details {
				float: none;
			}
		}
	}
}