@mixin b-pagination {
	.b-pagination {
		&-list {
			overflow: hidden;
			@include font(0, 0);
		}
		
		&-item {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px;
			@include font;
			
			&.first-last {
				display: none;
			}
		}
		
		&-link {
			@include transition;
			display: block;
			text-decoration: none;
			
			&:before {
				vertical-align: top;
			}
		}
		
		&-current {
			display: block;
			cursor: default;
		}
	
		&-hits {
			display: none;
		}
	}
}
