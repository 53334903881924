// Search results page

.pt_product-search-noresult {
	#secondary {
		display: none;
	}
}

.search-result {
	&-more {
		display: none;
		text-align: center;

		.b-button {
			margin-top: 20px;
			border-color: $color-black;

			&:hover,
			&:active {
				background-color: $color-black;
				color: $color-white;
			}
		}
	}

	&-section &-more {
		display: block;
	}

	&-section {
		position: relative;
		margin-bottom: 80px;
		padding-bottom: 80px;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: $plp-grid-section-gap-mobile;
			right: $plp-grid-section-gap-mobile;
			border-bottom: 1px solid $color-grey_light5;

			@include respond-to(desktop) {
				left: $plp-grid-section-gap-desktop;
				right: $plp-grid-section-gap-desktop;
			}

			@media only screen and (min-width: 1440px) {
				left: $plp-grid-section-gap-desktop-px;
				right: $plp-grid-section-gap-desktop-px;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;

			&:after {
				display: none;
			}
		}

		&:first-of-type {
			&:after {
				border-color: $color-black;
			}
		}

		&-title {
			text-transform: uppercase;
			padding-bottom: $plp-search-result-title-bottom-gap;
			padding-left: $plp-grid-section-gap-mobile;
			padding-right: $plp-grid-section-gap-mobile;
			@include font($font_size-h2, false);

			@include respond-to(desktop) {
				padding-left: $plp-grid-section-gap-desktop;
				padding-right: $plp-grid-section-gap-desktop;
			}

			@media only screen and (min-width: 1440px) {
				padding-left: $plp-grid-section-gap-desktop-px;
				padding-right: $plp-grid-section-gap-desktop-px;
			}
		}
	}
}
