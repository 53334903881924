@include b-order_details(14%);
@include b-order_summary;

.b-order {
	&-gift_message {
		padding: 0 20px 20px;
	}
}

.b-order_summary {
	margin-bottom: 20px;
	padding: 20px;
	background-color: $color-account-block;

	&-label {
		margin-bottom: 10px;
		@include font($font_size, false, $font_family);
		font-weight: 700;
		text-transform: uppercase;
	}

	.order-totals-table {
		td {
			padding: 0;
		}

		.order-subtotal,
		.order-total {
			text-transform: none;
		}

		.order-subtotal {
			font-weight: normal;
		}
	}
}

.b-order_shipment {
	margin-bottom: 20px;
	.b-order_table-simple &-details_title {
		@include respond-to(phone) {
			border-top: 5px solid $color-grey_light4;
		}
	}

	&-gift_message {
		padding: 0 15px 15px;
		background-color: $color-account-block;
	}
}
