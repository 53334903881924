.pt_checkout {
	.section-header {
		@extend %checkout-secondary-title;
	}
	
	.section-header-note {
		@extend %b-button-text;
		float: right;
		margin-top: 5px;
		color: $color-text_button;
	}
	
	.order-shipping a {
		display: none;
	}
	
	@include respond-to(not-phone) {
		.f-field-postal_code,
		#dwfrm_singleshipping_shippingAddress_addressFields_postal,
		#dwfrm_billing_billingAddress_addressFields_postal {
			width: 45%;
		}
	}

	fieldset {
		width: 100%;
	}
}

.b-checkout-secondary {
	@extend %checkout-secondary;
	position: relative;
	
	@include respond-to(tablet) {
		@include flexbox;
		@include wrap;
		padding: 0 $ch-gutter;
	}
}

.order-component-block {
	@extend %checkout-secondary-block;
	line-height: 24px;
	
	@include respond-to(tablet) {
		width: calc(50% - 10px);
		margin: 20px 0 0;
		
		&:nth-child(even) {
			margin-left: 20px;
		}
	}
}

.mini-shipment .name {
	display: none;
}
