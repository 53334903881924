.pt_storefront_simple {
	position: relative;
	min-height: 100vh;
	background-color: $color-black;
	color: $color-grey_light3;
	
	a {
		color: $color-grey2;
	}
}

.b-homepage-simple {
	max-width: 1000px;
	margin: 0 auto;
	padding: 30px $gutter-global 0;
	text-align: center;
	
	@include respond-to(not-desktop) {
		position: absolute;
		left: 0; right: 0;
		top: 40%;
		margin-top: -50px;
	}
}

.b-continent {
	&-title {
		@extend %heading-1;
		color: $color-white;
	}
	
	&-description {
		margin-bottom: 30px;
	}
	
	&-wrapper {
		text-align: left;
		letter-spacing: 0.25px;
	}
	
	&-name {
		color: $color-white;
		
		&.toggle {
			margin: 0 10px 10px;
			padding: 0 0 2px;
			border-bottom: 1px solid $color-none;
			background: none;
			
			&:hover,
			&.expanded {
				border-bottom-color: $color-grey2;
			}
		}
	}
	
	&-list {
		@include columns(4);
		margin: 40px -1px 0;
		line-height: 1.2;
	}
	
	&-item {
		padding: 0 20px 0 50px;
		border-right: 1px solid $color-black3;
	}
	
	&-link {
		display: inline-block;
		margin-bottom: 10px;
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
	
	&-select {
		margin-bottom: 20px;
		
		.f-select {
			background-color: $color-none;
			border-color: $color-black3;
			color: $color-grey2;
			letter-spacing: 0.25px;
		}
	}
	
	&-close {
		text-decoration: none;
		text-transform: uppercase;
		
		@include respond-to(not-phone) {
			position: fixed;
			top: 30px; right: 30px;
			
			&:before {
				@include font(25px, false);
			}
		}
		
		@include respond-to(desktop) {
			margin-top: $header-height;
		}
		
		@include respond-to(tablet) {
			margin-top: $header-height_tablet;
		}
		
		@include respond-to(phone) {
			&:before {
				content: none;
			}
		}
	}
}
