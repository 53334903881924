@include toggle;

.toggle-tabs {
	@include toggle-tabs;
	
	.toggle {
		margin: 0 5px 10px;
		padding: 8px 15px;
		background-color: $color-grey_light3;
		@include font($font_size, $letter_spacing-medium);
		line-height: 1;
		text-transform: uppercase;
		
		&.expanded {
			background-color: $color-black2;
			color: $color-white;
		}
	}
}

.toggle-tab {
	@include toggle-panel;
	margin-bottom: 20px;
	background-color: $color-white;
	
	.toggle {
		@include transition(background-color);
		@include font($font_size, false);
		font-weight: 600;
		line-height: 1;
		text-transform: uppercase;
		
		@include respond-to(desktop) {
			&:hover {
				background-color: $color-grey_light3;
			}
			
			&.expanded {
				&:hover {
					background: none;
				}
			}
		}
	}
}

.toggle-line {
	@include toggle-line;
	padding: 15px 0;
	border-bottom: 1px solid $color-grey_light3;
	
	&:first-child {
		padding-top: 0;
	}

	.toggle {
		&:after {
			@include font(8px, false);
		}
	}
}
