@mixin b-order_summary {
	.b-order_summary {
		@include respond-to(not-phone) {
			@include flexbox;
			@include wrap;
		}

		&-cell {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			@include respond-to(not-phone) {
				@include flex(1);
				margin-bottom: 0;
				padding-right: 20px;

				&:last-child {
					padding-right: 0;
				}

				&.order-payment-summary {
					min-width: 30%;
				}
			}

			&.order-information {
				@include respond-to(tablet) {
					@include flex(100%);
					margin-bottom: 20px;
				}
			}
		}

		.order-status {
			display: none;
		}
	}
}
