@mixin create_account_form(
  $form_width: 740px
) {
  .b-account-register {
    width: $form_width;
    max-width: 100%;
    margin: 0 auto;

    .f-field-button {
      margin-top: 30px;
      text-align: center;
    }
  }

  .b-create_oauthaccount {
    width: $form_width/2;
    max-width: 100%;
    margin: 0 auto;
  }
  .b-create_account {
    &-privacy_policy {
      position: relative;
      @include flexbox();
      align-items: baseline;
      margin: 15px 0 0;
      .f-label-value,
      .f-required-indicator,
      .f-caption {
        display: none
      }
      .error {
        position: absolute;
        top: -20px;
      }
    }

    &-privacy_label {
      font-size: 12px;
      color: $color-grey;
      text-align: left;
      a {
        color: $color-grey;
      }
    }
    &-privacy_consent {
      margin: 15px 0;
      font-size: 12px;
      color: $color-grey;
    }

    .f-field-recaptcha {
      display: none;
    }

    .f-field-recaptcha.recaptcha-error {
      display: block;
    }

    .g-recaptcha {
      margin: 0 0 15px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
