@include main-navigation;

body:has(.b-main_navigation.active) {
    overflow: hidden;
}

.b-main_navigation {
	.header-menu_toggle {
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 2;
		cursor: pointer;

		@include respond-to(phone) {
			display: none;
		}
	}

	.b-navigation_wrapper {
		background-color: #fff;
		max-width: 767px;
		width: 100%;
		height: 100%;
		margin-left: auto;
		padding: 80px 35px 25px 75px;
		overflow: hidden;

		@include respond-to(phone) {
			padding: 30px 25px 90px 20px;
			max-height: calc(100vh - 52px);
			height: 100vh;
		}
	}

	.b-navigation {
		text-align: start;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		position: relative;
		z-index: 1;
		border: none;
		height: 100%;
		overflow-y: auto;

		&-banner {
			float: none;
		}

		&::-webkit-scrollbar {
			width: 5px;
			border-radius: 9px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #EAEAEA;
			border-radius: 9px;
		}

		&.b-navigation-level_1.open-level {
			& > .b-navigation-top_item:not(.open-level) {
				display: none;
			}

			.b-navigation-level_2.open-level {
				& > .b-navigation-column:not(.open-level) {
					display: none;
				}
			}
		}

		.b-navigation-top_item {
			position: unset;

			@include respond-to(not-phone) {
				margin-bottom: 2%;
			}

			@include respond-to(phone) {
				margin-bottom: 3%;
			}

			&:hover .b-navigation-dropdown {
				display: none;
			}

			&#item-crypto .b-navigation-top_link {
				.b-navigation-text {
					position: relative;
				}
				.b-navigation-text::after {
					content: '';
					background-image: url(../images/crypto-icon.svg);
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 19px;
					height: 20px;
					position: absolute;
					right: -25px;

					@include respond-to(phone) {
						width: 16px;
						height: 17px;
					}
				}
			}
		}

		.js-navigation-item {
			position: relative;
			overflow: hidden;

			.menu-item-toggle {
				&::before {
					content: none;
				}
			}

			& > .b-navigation-banner_subcat {
				display: none;
			}

			&.active {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				background-color: #fff;
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 5px;
					border-radius: 9px;
			    }

				&::-webkit-scrollbar-thumb {
					background-color: #EAEAEA;
					border-radius: 9px;
				}

				&::after {
					content: '';
				}

				.menu-item-toggle.active {
					position: relative;
					top: -10px;
					padding: 10px 15px;
					margin-bottom: 20px;
					color: #000;

					@include respond-to(phone) {
						margin-bottom: 10px;
					}

					&::before {
						content: attr(data-arrow);
						font-size: 12px;
						font-weight: 700;
						letter-spacing: 0.36px;
						text-transform: uppercase;
						text-decoration: underline;
						cursor: pointer;
						font-family: $font_family;
					}

					&::after {
						position: absolute;
						z-index: -1;
						top: 10px;
						left: 0;
						transform: rotate(180deg);
					}
				}

				& > .b-navigation-banner_subcat {
					display: block;
				}

				.b-navigation-item.active .b-navigation-link.m-level_2,
				.b-navigation-top_link {
					display: inline-block;
					@include font(31px, 0.93px);
					font-weight: 400;
					line-height: normal;
					margin-bottom: 10px;
					text-transform: capitalize;
					color: #000;

					@include respond-to(phone) {
						@include font(28px, 0.84px);
					}
				}
			}
		}

		.b-navigation-bottom_item {
			@include respond-to(not-phone) {
				margin-bottom: 3%;
			}

			&.b-navigation-bottom_first {
				margin-top: 55px;
				border-top: 1px solid rgba(0, 0, 0, 0.1);
				padding-top: 50px;

				@include respond-to(phone) {
					margin-top: 20px;
					padding-top: 35px;
				}
			}

			&:not(.b-navigation-bottom_first) {
				@include respond-to(phone) {
					margin-top: -4%;
				}
			}

			&::after {
				content: none;
			}

			@include font(13px, 0.39px);
			font-weight: 700;
			line-height: normal;
			color: #000;

			a,
			.b-utility_menu-link {
				display: inline-block;
				line-height: normal;
				@include font(13px, 0.39px);
				line-height: normal;
				text-transform: capitalize;
				text-align: start;
				text-decoration: underline;
				color: #000;
				width: max-content;
				min-height: 30px;

				span {
					display: inline;
					line-height: normal;
				}

				&:hover {
					text-decoration: none;
				}
			}

			.icon-crypto {
				position: relative;

				&::after {
					content: '';
					background-image: url(../images/crypto-icon.svg);
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 15px;
					height: 16px;
					position: absolute;
					right: -25px;
				}
			}
		}

		&-menu.b-navigation-level_2,
		&-menu.b-navigation-level_3 {
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 18px;
			background-color: #fff;
		}

		&-menu.b-navigation-level_3 {
			.b-navigation-banner_subcat {
				display: flex;
			}
		}

		.menu-item-toggle {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 1;
			line-height: 1;
			padding: 0;
			text-align: unset;
		}

		.icon-arrow::after {
			content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjE4MjA0IDEuMTcxNTJDMS4yNzkzNyAxLjA3NjAzIDEuNDM1MjQgMS4wNzYwNCAxLjUzMjU2IDEuMTcxNTNMNS4zMjA5OSA0Ljg4ODk1TDEuNTMyMzEgOC42MDU2N0MxLjQzNTEyIDguNzAxMDEgMS4yNzk1IDguNzAxIDEuMTgyMzIgOC42MDU2NUMxLjA4MjQ3IDguNTA3NjkgMS4wODI0OCA4LjM0Njg2IDEuMTgyMzMgOC4yNDg5MUw0LjYwNjg4IDQuODg5NDVMMS4xODIwMyAxLjUyODc1QzEuMDgyMDYgMS40MzA2NiAxLjA4MjA3IDEuMjY5NjEgMS4xODIwNCAxLjE3MTUyWiIgZmlsbD0iYmxhY2siIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMC44Ii8+Cjwvc3ZnPgo=);
			position: relative;
			top: 4px;
			left: 95%;
			line-height: 1;

			@include respond-to(phone) {
				left: calc(100% - 24px);
			}
		}

		&-item {
			margin-top: 0;
		}

		.b-navigation-item,
		.b-navigation-top_item {
			border-top: none;
		}

		a {
			padding: 0;

			&:active {
				background-color: transparent;
			}
		}

		&-top_link {
			min-height: $navigation-height;
			@include font(16px, 0.48px);
			color: $color-black;
			text-transform: uppercase;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			cursor: pointer;
			padding: 0;

			@include respond-to(phone) {
				min-height: 25px;
				@include font(13px, 0.39px);

				-webkit-tap-highlight-color: transparent;
			}
		}

		&-link {
			color: $color-black;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.48px;
			text-transform: capitalize;

			@include respond-to(phone) {
				&:hover,
				&:active,
				&:target {
					background-color: #fff;
				}

				-webkit-tap-highlight-color: transparent;
			}
		}
	}
}

@include respond-to(not-phone) {
	.b-main_navigation {
		margin: 0 auto;
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-color: transparent;

		&.b-main_navigation {
			width: unset;
		}

		&.active::before {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			background-color: rgba(0,0,0,.5);
		}
	}
}

@include respond-to(not-phone) {
	.b-navigation {

		&-link.disabled,
		&-top_link.disabled,
		&-top_item.disabled {
			pointer-events: none;
		}

		&-banner {
			margin-top: 15px;
		}

		&-link {
			text-transform: uppercase;
		}

		&-top_link.has-sub-menu,
		&-link {
			.b-navigation-text {
				padding-right: 11px;
				position: relative;

				&::after {
					content: none;
					position: absolute;
					top: 0;
					right: -8px;
				}
			}
		}

		&-dropdown {
			position: unset;
			background-color: $color-white;
			z-index: $z-min;
			top: auto;
			height: auto;
			display: none;
		}

		&-top_item .js-navigation-item.active {
			.b-navigation-dropdown {
				display: block;
			}
		}


		&-level_2.m-horizontal > &-item {
			width: 185px;
			padding: 0 15px;
		}

		&-level_2.m-horizontal {
			.b-navigation-column {
				width: 100%;
			}
		}

		&-banner {
			margin-top: 0;

			.banner-item {
				position: relative;
				text-align: center;
			}

			.banner-info {
				position: absolute;
				left: 0; bottom: 0;
				width: 100%;
				padding-bottom: 20px;
			}

			.banner-title {
				margin-bottom: 20px;
				@include font($font_size-h2, $letter_spacing-medium, $font_family-additional);
				color: $color-black;
				text-transform: uppercase;
			}

			.banner-link {
				@include transition();
				display: inline-block;
				padding: 8px 20px;
				@include font(15px, false);
				font-weight: 600;
				color: $color-black;
				text-transform: uppercase;
				text-decoration: none;
				background-color: $color-white;
				border: 1px solid $color-white;

				&:hover {
					background-color: $color-none;
					color: $color-white;
				}
			}
		}

		&-view_all &-link {
			@include font($font_size-small, $letter_spacing-medium);
		}
	}
}

@include respond-to(desktop-small) {
	.b-navigation {
		&-top_link {
			font-size: .8rem;
		}
		&-top_item {
			margin: 0 6px;
		}
	}
}

@include respond-to(not-desktop) {
	.b-main_navigation {
		z-index: $z-min;
		border-bottom: 1px solid $color-black;
	}

	.b-navigation {
		.menu-item-toggle {
			color: $color-black;

			&.active {
				color: $color-white;
			}
		}

		&-link {
			color: $color-black;
			text-transform: uppercase;
		}

		&-dropdown,
		&-item {
			background-color: $color-white;

			a {
				color: $color-black;
			}
		}

		&-item.active,
		&-top_item > .active {
			background-color: $color-black;

			> a {
				color: $color-white;
			}
		}

		&-item.active > a {
			border-top: 1px solid $color-white;
		}

		&-bottom_item {
			&:before {
				content: none;
			}

			&:first-child {
				position: relative;
				border: 0;

				&:after {
					content: '';
					position: absolute;
					top: 20px; bottom: 20px;
					right: 0;
					width: 1px;
					background-color: $color-black;
				}
			}

			.b-shipto {
				position: relative;
				top: 20px;
				display: block;
				padding-left: 20px;
				margin-bottom: -10px;
				color: $color-grey;
				@include font(10px, false);
				line-height: 1;
				text-transform: uppercase;
			}

			.b-navigation-bottom_link {
				padding: 30px 20px;
				@include form-select();
				color: $color-black;
			}
		}

		.b-navigation-item {
			border-color: $color-black;
		}

		.b-navigation-top_link {
			color: $color-black;
		}
	}
}

.menu-toggle {
	&.active {
		background: none;
		color: $color-black;
	}
}
