.b-pdp {
	&-accordeon {
		position: relative;
		clear: both;
		padding-top: 10px;
		text-align: left;
		
		.b-social_sharing,
		.b-store_availability {
			float: left;
			
			&-button {
				@include font(13px, false);
				text-decoration: none;
				
				> span {
					pointer-events: none;
				}
			}
		}
		
		.b-social_sharing {
			float: right;
			
			&-button:before {
				@include transform(rotate(180deg));
			}
		}

		.b-store_availability {
			max-width: 65%;

			&-button {
				padding-left: 20px;

				&:before {
					margin-left: -20px;
				}
			}
		}
	}
	
	&-accordeon_item {
		border-top: 1px solid $color-pdp;
		position: relative;
		
		&:last-child {
			overflow: hidden;
			padding-top: $pdp-info-section-indent;
		}
	}
	
	&-accordeon_label {
		padding-top: $pdp-info-section-indent;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: $letter_spacing-medium;
		text-transform: uppercase;
		
		&:before {
			display: none;
		}
		
		&.toggle {
			padding-bottom: $pdp-info-section-indent;

			&:after {
				content: icon-char(plus);
				position: absolute;
				right: 0;
			}
			
			&.expanded:after {
				content: icon-char(minus);
			}
		}
	}
	
	&-accordeon_content {
		margin: -5px 0 $pdp-info-section-indent;
		@include font(12px);
	}
	
	&-product_composition {
		margin-top: 15px;
		
		.label,
		.value {
			display: inline;
		}
		
		.label:after {
			content: ':';
		}
	}
}

.b-store_availability-button {
	@include icon(point);
}
