@include tooltip();

.b-tooltip {
	&, &-title {
		color: $color-grey2;
		@include font(10px, false);
	}
	
	&-title {
		text-transform: uppercase;
	}
}
