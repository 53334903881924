//No Search Results

.b-no_hits {
	color: $color-text_light;
	text-align: center;
	width: 460px;
	max-width: 100%;
	margin: 0 auto;
	padding: 50px 30px 80px;
	
	@include respond-to(phone) {
		padding: 70px 30px;
	}
	
	&-title {
		@extend %heading-2;
		font-weight: 400;
		margin-bottom: 30px;
		
		@include respond-to(phone) {
			@include font(24px, false);
		}
	}

	&-section_header {
		@include font($font_size-small, false, $font-family_additional);
		color: $color-text;
		font-weight: 600;
		margin-bottom: 30px;
	}

	&-help_title {
		color: $color-text;
		font-weight: 600;
		letter-spacing: $letter-spacing_medium;
		text-transform: uppercase;
	}

	&-search_term {
		@include font($font_size-small, false, $font_family);
		color: $color-grey;
		text-transform: uppercase;
	}
	
	&-search_tips {
		@include font(11px, false, $font_family-additional);
		font-weight: 600;
		margin-top: 10px;
	}
	
	&-search_form {
		margin: 30px auto 0;
		
		&.b-search-form form {
			text-align: center;
		}
	}
	
	&-search_title {
		@include font(18px, false);
		color: $color-text;
		line-height: 1.5;
		text-transform: uppercase;
	}
	
	&-help {
		margin-top: 20px;
	}
}
