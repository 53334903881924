@include user-menu;

.p-login_popup {
	background: none;
}

.b-header-login_dropdown {
	background-color: $color-white;
	border: 1px solid $color-black;
}

#iframe-login {
	background-color: $color-white!important;
}