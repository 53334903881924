@include minicart;

.b-header-minicart {
	@include respond-to(desktop) {
		&:hover .b-utility_menu-link{
			color: $color-black;
		}
	}
}

.b-mini_cart {
	&-link {
		position: relative;

		> .minicart-label {
			display: none;
		}
		
		.minicart-quantity {
			@include font(8px, false);
			font-weight: 700;
			vertical-align: middle;

			&:before,
			&:after {
				content: none;
			}

			border: 1px solid #000;
			border-radius: 50%;
			width: 14px;
			height: 14px;
			@include flexbox;
			@include jc-center;
			@include ai-center;
			position: absolute;
			top: 30%;
			right: -4px;

			@include respond-to(not-desktop) {
				display: flex;
				max-width: unset;
				right: -3px;
			}
		}
	}

	&-content {
		color: $color-black;
		background-color: $color-white;
		box-shadow: 0 0 2px 0 $color-black;

		::-webkit-scrollbar-track,
		::-webkit-scrollbar-corner {
			background: $color-white;
		}

		right: -53px;
	}
	
	&-header {
		padding: 8px 20px 8px 0;
		color: $color-black;
		@include font($font_size-xs, $letter_spacing-medium);
		font-weight: 700;
		text-align: right;
		text-transform: uppercase;
		background-color: $color-white;
		border-bottom: 1px solid $color-black;

		@include respond-to(not-desktop) {
			padding-right: 30px;
		}
	}
	
	&-products {
		line-height: 18px;
		
		@include respond-to(desktop) {
			margin: 10px 10px 0;
			padding: 10px;
		}
		
		@include respond-to(not-desktop) {
			margin: 15px 20px;
			padding: 0 10px;
		}
	}
	
	&-product {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px solid $color-black;
		
		@include respond-to(not-desktop) {
			margin-top: 15px;
			padding-top: 15px;
		}
		
		&:first-child {
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
		}
	}
	
	&-image {
		width: 75px;
		margin: 2px 10px 0 0;
	}

	&-name {
		color: $color-black;
		font-weight: 600;
		margin-bottom: 10px;
	}

	&-pricing > span {
		font-weight: 600;
	}

	&-price-unadjusted > span,
	&-pricing > span,
	&-attributes {
		color: $color-grey2;
	}
	
	&-subtotals-unadjusted {
		color: $color-grey2;
	}

	&-totals {
		padding: 20px;
		border-top: 1px solid $color-black;

		@include respond-to(not-desktop) {
			padding: 10px 30px 20px;
			border-top-color: $color-black;
		}

		.b-mini_cart-totals_row .label {
			width: 100%;
		}
	}

	&-slot .html-slot-container {
		margin-top: 10px;
		@include font($font_size-small, false);
		color: $color-grey;
	}

	&-adjustments {
		.promo-adjustment > * span {
			display: contents
		}

		.promo-adjustment  > * {
			white-space: nowrap;
		}
	}
}
