@include pdp-product_360view; 

.b-360view {
	&-progress {
		@include font($font_size-h1, false, $font_family-additional);
	}
	
	&-zoom {
		@include respond-to(not-phone) {
			top: $gutter-pdp;
		}
		
		@include respond-to(desktop) {
			left: $gutter-pdp;
		}
	}
	
	> .b-zoom-link {
		&:before {
			content: '360\00B0';
			@include font(10px, $letter_spacing-medium, $font_family);
		}
		
		@include respond-to(not-phone) {
			top: $gutter-pdp;
		}
	}
}
