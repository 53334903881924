.b-checkout-shipping_type {
	@extend %checkout-primary-block;

	.b-shipping_method {
		&:last-child {
			margin-bottom: 0;
		}

		&-name {
			margin-right: 0;

			&:after {
				content: none;
			}
		}
	}

	.f-label {
		display: inline-block;
		vertical-align: middle;
	}

	.f-caption {
		display: inline;
		margin-left: 0;

		&:before {
			content: '-';
			margin-right: 5px;
		}
	}
}

.b-checkout-shipping,
.b-checkout-stores {
	@include flexbox;
	@include wrap;
}

.h-visible_shipping {
	display: none;
	
	.checkout-shipping & {
		display: block;
	}
}

.f-disable_shipping {
	.checkout-shipping & .f-select,
	.checkout-shipping & .f-textinput {
		pointer-events: none;
		opacity: 0.5;
	}
}

// Gift Wrapper
.b-checkout-gift_wrapper {
	@extend %checkout-primary-block;
	margin-top: 40px;
	
	.f-field {
		margin-bottom: 0;
	}
	
	.f-field-radio {
		margin-bottom: 5px;
		
		> .f-label {
			@extend %heading-3;
			@include font($font_size, false);
		}
		
		> .f-label,
		.f-radio-group {
			display: inline-block;
			vertical-align: middle;
			margin: 0 30px 0 0;
		}
	}
}

// Shipping Methods
.b-checkout-shipping_methods,
.b-checkout-shipping_comment {
	@extend %checkout-primary-block;

	@include respond-to(not-phone) {
		float: left;
		width: 50%;
	}
}

.b-shipping_method {
	.f-label {
		font-weight: 600;
	}
	
	.f-caption {
		color: $color-text_details;
	}
	
	&-name {
		&:after {
			content: ':';
		}
	}
	
	&-tooltip {
		@extend %b-button-text;
		margin-left: 5px;
	}
	
	&-tooltip_item {
		overflow: hidden;
		min-width: 250px;
		margin-bottom: 10px;
		line-height: 1;
	
		&.shippingtotal {
			margin-bottom: 0;
		}
		
		.label {
			float: left;
			width: 72%;
			text-align: right;
		}
		
		.value {
			float: right;
			width: 24%;
			font-weight: 700;
		}
	}

	&-delivery_date {
		font-weight: 600;
		text-transform: capitalize;
	}
	
	.standard-shipping {
		text-decoration: line-through;
		color: $color-grey2;
	}
}

.b-checkout-shipping_comment {
	.f-label {
		@extend %heading-3;
	
		@include respond-to(phone) {
			text-align: center;
		}
	}
	
	.f-textarea {
		width: 100%;
	}
}

.b-checkout_store {
	margin-top: 10px;

	&-map {
		height: 300px;
		width: 100%;
	}

	.b-store_locator-details_title {
		@extend %heading-3;
	}

	.b-store_locator-details_info {
		@include clearfix;
		padding: 0;

		@include respond-to(not-phone) {
			.b-store-events {
				margin-top: 15px;
			}
		}
	}

	.b-store-image {
		text-align: right;
	}

	.b-store-label {
		@include font($font_size, false);
	}
}