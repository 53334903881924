/*
---
name: banner titles
tag: titles
category: typography
---
 
```html
    <div class="sg-banner b-banner">
        <div class="m-abs-bottom m-text-center">
	        <h4 class="b-banner-subtitle">Subtitle</h4>
	        <h3 class="b-banner-title">Title here</h3>
	    </div>
    </div>
```
*/

.b-banner {
	@include b-banner;
	@include font(14px, false);
	line-height: 1.5;
	text-align: center;
		
	@include respond-to(phone) {
		@include font(11px, false);
	}

	&-video:before {
		@extend %bg-pp;
	}
	
	&-title {
		@extend %heading-1;
		
		@include respond-to(phone) {
			margin-bottom: 15px;
		}
	}
	
	&-subtitle {
		@extend %heading-3;
		letter-spacing: 2px;
		
		@include respond-to(tablet) {
			@include font(14px, false);
		}
		
		@include respond-to(phone) {
			@include font(12px, false);
		}
	}
	
	&-button {
		margin: 0 15px 15px;
		
		@include respond-to(phone) {
			width: 100%;
			margin: 0 0 15px;
		}
	}
	
	&-content_wrapper {
		padding: 20px;
		
		@include respond-to(phone) {
			padding: 15px;
		}
	}

	&-product_price {
		display: block;
		margin-bottom: 10px;
	}

	&-product_description {
		margin-bottom: 5px;
	}
	
	&.m-large & {
		&-title {
			@include respond-to(desktop) {
				@include font(46px, false);
			}
			
			@include respond-to(tablet) {
				@include font(40px, false);
			}
		}
		
		&-subtitle {
			@include respond-to(desktop) {
				@include font(18px, false);
			}
			
			@include respond-to(tablet) {
				@include font(16px, false);
			}
		}
	
		&-content_wrapper {
			padding-bottom: 25px;
		}
	}
	
	&.m-small & {
		&-title {
			margin-bottom: 10px;
			@include font(20px);
		
			@include respond-to(phone) {
				@include font(16px, false);
			}
		}
		
		&-subtitle {
			margin-bottom: 0;
			@include font(14px);
		
			@include respond-to(phone) {
				@include font(11px, false);
				font-weight: 700;
			}
		}
		
		&-content_wrapper {
			@include font(13px, false);
			
			@include respond-to(phone) {
				padding: 10px;
			}
		}
	}
	
	&.m-hovered & {
		&-content_wrapper {
			@include transition;
			background-color: $color-black-transparent;
			color: $color-white;
			
			position: absolute;
			left: 0; right: 0;
			bottom: 0;
			
			.desktop & {
				bottom: -10%;
				opacity: 0;
			}
		}
	}
	
	&.m-hovered:hover & {
		&-content_wrapper {
			.desktop & {
				bottom: 0;
				opacity: 1;
			}
		}
	}
	
	&.m-static-mobile & {
		&-content_wrapper {
			@include respond-to(phone) {
				@include transform(none);
				position: static;
				
				&.m-color-white {
					background-color: $color-black;
				}
			}
		}
	}

	&.m-inline &,
	&.m-product & {
		&-content_wrapper,
		&-subtitle {
			@include font(12px);
		}

		&-subtitle {
			margin-bottom: 0;
		}
	}

	&.m-inline {
		display: block;
		
		.b-banner-content_wrapper {
			padding: 15px 20px 0;
		}
	}

	&.m-product {
		background-color: $color-white;
		
		.grid-banner & {
			width: auto;
			margin: 0 1px;

			&:before {
				padding-bottom: 0 !important;  // not needed fake gap for tiles
			}
		}
		
		.b-banner-content_wrapper {
			padding: 0;
		}
		
		.b-product_tile {
			margin: 0;
		}
		
		.b-product_tile-action.b-product_tile-action {
			display: none;
		}
		
		p {
			margin: 7px 0;
		}
	}
	
	&.js-loading {
		&:before {
			background-color: $color-black;
		}
		
		&:after {
			@include invert;
		}
	}

	&-timer {
		margin-top: 0.75em;
		font-weight: 600;
	}
	
	.b-button {
		border-width: 2px;
	}
}

.b-banner_text {
	background: #77808f;
	
	&:before {
		float: left;
		content: "";
		padding-bottom: percentage(350/480);
		
		@include respond-to(phone) {
			padding-bottom: 0;
		}
	}
	
	@include respond-to(phone) {
		.b-banner {
			&-content_wrapper {
				@include transform(none);
				position: static;
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}
	}
}

.b-banner-carousel {
	margin-bottom: -1px;
	
	&.b-product_carousel {
		.owl-prev,
		.owl-next {
			width: 25%;
		}
	}
	
	.grid-tile {
		@include flexbox;
		overflow: hidden;
		
		&:before {
			float: left;
			content: "";
			padding-bottom: percentage(350/480);
			
			@include respond-to(phone) {
				padding-bottom: 0;
			}
		}
	}
	
	.tiles-container > .grid-tile {
		@include flexbox-inline;
		width: 100%;
	}
	
	.b-product_tile {
		@include flexbox;
		@include flex-column;
		@include jc-end;
		@include flex(1);
		margin: 20px;
		@include font(14px, false);
	
		@include respond-to(phone) {
			margin: 15px;
		}
		
		&-image {
			width: 40%;
			margin-left: auto;
			margin-right: auto;
		}
		
		&-name {
			@include font(14px, false);
		}
		
		&-pricing,
		&-promotion,
		&-action_wrapper {
			display: none;
		}

		&-link {
			display: inline;
			color: $color-grey;
			text-decoration: none;
			text-transform: uppercase;
		}
	}
}

.b-content-carousel {
	background-color: $color-black;
	
	.owl-next,
	.owl-prev {
		@include respond-to(phone) {
			max-width: 50px;
			max-height: 280px;
		}
	}
}

.m-shadowed_controls {
	.owl-next,
	.owl-prev {
		@include respond-to(not-phone) {
			background-color: $color-black;
			box-shadow: 0 0 120px 80px $color-black;
			opacity: 0.8;
		}
	}
}
