@mixin stop_fake_form(
	$input-width: 350px,
	$input-gutter: 20px,
	$icon-size: 15px
) {
	.b-stop_fake_form {
		@include clearfix;
		
		&-wrapper {
			@include order(1);
			@include flexbox;
			max-width: 100%;
		}
	
		&-input_wrapper {
			@include flex(1);
			max-width: $input-width;
		}
		
		.f-field {
			margin-bottom: 0;
		}
		
		.f-label {
			display: none;
		}
	
		.f-textinput,
		.b-button {
			@include text-ellipsis;
		}
		
		.f-textinput {
			width: 100%;
			background: none;
		}
		
		.b-button {
			height: $input-height;
			margin: 0 0 0 -1px;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	.b-stop_fake_feed {
		&-form {
			width: 2 * $input-width + $input-gutter;
			max-width: 100%;
		}
	}
	
	.b-stop_fake_form,
	.b-stop_fake_feed {
		&-message {
			&:before {
				@include icon-styles(15px);
				width: $icon-size;
				height: $icon-size;
				margin-right: 10px;
				line-height: 1;
				text-align: center;
				text-indent: 1px;
			}
		}
	}
}
