.pdp-main {
	position: relative;
}

.b-pdp {
	&-product_view {
		@include clearfix;
		padding: 0 25px 80px 25px;

		@include respond-to(phone) {
			padding-top: 10px;
			padding-bottom: 40px;
		}
	}

	&-product_info_wrapper {
		@include respond-to(desktop) {
			float: right;
			width: 35%;
			padding-left: $gutter-pdp;
		}

		@include respond-to(tablet) {
			float: right;
			width: 45%;
		}

		@include respond-to(phone) {
			width: 100%;
		}
	}

	&-product_info {
		position: relative;
		padding-top: $pdp-info-section-indent;
		z-index: $z-pdp-info;

		.availability-instore,
		.availability-web {
			padding-bottom: 15px;
		}

		.inventory {
			display: none;
		}

		.product-main-attributes {
				margin: 5px 0;

				.value {
					font-weight: 700;
				}
		}

		.product-options {
			width: 100%;
			margin-bottom: 15px;

			.input-select {
				@extend %f-select;
				width: 100%;
			}
		}
	}

	&-product_made-in {
		margin-top: 15px;
		color: $color-grey;
	}

	&-product_number,
	.product-main-attributes {
		color: $color-grey2;
		@include font($font_size-small, false);
		text-transform: uppercase;

		@include respond-to(phone) {
			@include order(1);
		}
	}

	&-product_number {
		margin-bottom: $pdp-info-section-indent;
		order: 2;
	}

	&-general_info {
		margin-bottom: $pdp-info-section-indent;
		@include flexbox;
		@include flex-column;

		@include respond-to(not-phone) {
			position: relative;
		}
	}
	&-order-duties {
		position: relative;
		order: 7;

		.order-duties-title {
			display: inline-block;
			margin: 10px 0 0;
			color: $color-grey2;
			font-size: 9px;
			font-weight: 700;
			text-transform: uppercase;
			cursor: pointer;
		}
		.order-duties-content {
			font-size: 12px;
			padding: 15px;
		}
	}

	&-share-wrap {
		position: absolute;
		right: 0;
	}
	&-social {
		margin-left: auto;
	}

	&-product_name {
		margin-bottom: 5px;
		@include font(26px, $letter_spacing-medium, $font_family-additional);
		line-height: 1;
		text-transform: uppercase;
		order: 1
	}
	&-product_labels_wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&-product_label {
		@include label(13px);
		margin: 0;
		padding-left: 0;

		@include respond-to(phone) {
			display: inline-flex;
			align-self: flex-start
		}

		.look-back {
			display: none;
		}
	}

	&-pricing {
		text-transform: uppercase;
		order: 4;

		.promotion,
		.product-price {
			@include respond-to(desktop) {
				display: inline-block;
			}
		}

		.product-price {
			@include respond-to(desktop) {
				display: inline;
				margin-right: 10px;
			}
		}

		.product-price > div,
		.salesprice,
		.price-sales {
			@include respond-to(desktop) {
				float: left;
			}
		}

		.price-tiered {
			margin: 5px 0;
			@include font($font_size-small, false);
			color: $color-red;

			@include respond-to(desktop) {
				float: left;
				clear: both;
			}

			&-values {
				color: $color-text;
			}
		}

		.price-standard {
			float: left;

			&:after {
				content: "|";
				display: inline-block;
				margin: 0 10px;
			}
		}

		.product-price,
		.price-sales {
			@include font(18px, false, $font_family);
			font-weight: 900;
		}

		.promotion,
		.price-standard {
			@include font(16px, false);
		}

		.promotion {
			text-transform: none;

			@include respond-to(not-desktop) {
				margin-top: 5px;
			}

			&-title {
				display: none;
			}

			.callout-message ~ .callout-message {
				@include respond-to(desktop) {
					margin-left: 5px;
				}
			}

			.b-tooltip-link {
				@include font($font_size-small, false);
				color: $color-grey2;
			}
		}

		@include respond-to(phone) {
			@include order(1);
		}
	}
	&-promotion {
		order: 5;
	}

	&-product_additional_block {
		clear: both;
	}

	&-tags {
		@include pdp-tags;
		display: none;
	}

}
