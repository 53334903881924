@mixin b-banner {
	position: relative;
	overflow: hidden;
	
	.owl-carousel & {
		overflow: visible;
	}

	.owl-loaded & {
		float: none;
		width: auto;
	}
	
	&-image {
		width: 100%;
	}

	&-link {
		position: absolute;
		z-index: $z-min;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	
	&-video {
		position: relative;
		overflow: hidden;
		padding-bottom: percentage(720 / 1280);
		
		&:before {
			content: '';
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			@include animation(loader 2s infinite ease-in-out)
		}
		
		> iframe {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			cursor: pointer;
		}
		
		@include respond-to(desktop) {
			&.m-vimeo,
			&.m-autoplay {
				> iframe {
					top: -100%;
					height: 300%;
				}
			}
		}
		
		@include respond-to(not-desktop) {
			display: none;
		}
	}
	
	&-video + &-picture {
		@include respond-to(desktop) {
			display: none;
		}
	}
	
	&-carousel {
		.owl-stage {
			@include flexbox;
			@include ai-center;
		}
	}
	
	&.js-loading:after {
		.desktop & {
			background-attachment: scroll;
		}
	}
	
	&.m-fix_bottom_line {
		margin-bottom: -1px;
	}
}
