.b-account {
	&-nav {
		@include respond-to(not-desktop) {
			border-bottom: 1px solid $color-grey_light2;
		}
	}

	&-nav_link {
		@include font($font_size, $letter_spacing-medium);

		display: block;
		padding: 20px 0 20px 25px;
		text-transform: uppercase;
		text-decoration: none;
		
		@include respond-to(desktop) {
			@include transition(background-color);
			background-color: $color-white;
			border-bottom: 5px solid $color-grey_light4;
		
			&.active,
			.desktop &:hover {
				background: $color-grey_light3;
			}
		}

		@include respond-to(not-desktop) {
			display: none;
			text-align: center;
			border-top: 1px solid $color-grey_light2;
			
			.js-inited & {
				@include transition;
				display: block;
				max-height: 0;
				padding: 0;
				border-top: 0;
				overflow: hidden;
			}
			
			&,
			.js-open &,
			.js-inited &.active {
				max-height: 120px;
				padding: 20px 35px;
				border-top: 1px solid $color-grey_light3;
			}
			
			&.active {
				@include icon(arrow-down, after, false);
				position: relative;
				
				&:after {
					position: absolute;
					top: 50%;
					right: 15px;
					@include font(9px, false);
					margin-top: -4px;
				}
			}

			.js-open &.active:after {
				@include transform(rotate(180deg));
			}
			
			&.b-account-nav_placeholder {
				display: block;
				min-height: 60px;
				
				> span {
					display: none;
					
					.js-inited & {
						display: block;
					}
				}
			}
		}
	}
}

.js-without-palceholder {
	.b-account-nav_placeholder.active {
		display: none;
	}
}
