.b-account {
	&-primary_title {
		@include font($mx-font-size:20px, $mx-font-family: $font_family-additional);
		
		margin-bottom: 20px;
	}

	&-title {
		padding: 20px 0;
		text-align: center;
		@include font($font_size-small, $letter_spacing-medium);
		font-weight: 700;
		text-transform: uppercase;
		border-bottom: 1px solid $color-grey_light3;

		@include respond-to(not-desktop) {
			display: none;
		}
	}

	@include respond-to(not-desktop) {
		.b-primary:first-child {
			margin-top: 20px;
		}
	}
}
