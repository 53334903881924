.b-return {
	&-item {
		@include font(0, 0);
		margin-bottom: 5px;
		padding: 20px 15px;
		background-color: $color-account-block;

		&_col {
			display: inline-block;
			vertical-align: top;
			width: 25%;
			padding-right: 20px;
			@include font($font_size-small, false, $font_family-additional);
		}

		&_col:last-child {
			text-align: right;
		}

		.b-return-item_qty {
			width: 12%;
		}

		.b-return-item_button {
			padding: 0;
		}

		.label {
			display: block;
			margin-bottom: 10px;
			@include font($font_size, false, $font-family);
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	
	&-not_found_message {
		margin-bottom: 20px;
		font-family: $font_family-additional;
		text-align: center;
		
		.b-return_back + & {
			padding: 0;
			margin-top: 20px;
			font-family: $font_family;
			text-align: left;
		}
	}
	
	@include respond-to(not-desktop) {
		&-not_found_wrapper {
			text-align: center;
		}
	}

	@include respond-to(phone) {
		&-item .label{
			display: inline-block;
			margin: 0 5px 0 0;

			&:after {
				content: ':';
			}
		}

		&-item_button {
			margin-bottom: 0;
			text-align: center;
		}

		&-item_button .f-view-returns {
			width: 100%;
			max-width: 320px;
		}
	}
}