@include social_sharing;
@include social_sharing_popup;

.dialog-social_share {
	.ui-dialog-title {
		margin-bottom: 10px;
		@include font(13px, false);
		font-weight: 500;
	}
}

.dialog-wechat_share {
	max-width: 340px;
}

.b-social_sharing {
    &-button {
        text-decoration: none !important;
        &:before {
            content: "";//override content set by .icon-share (can't remove class - used on all sites)
            background: url("../../../static/images/icon-share.svg") center/cover no-repeat;
            $size: 26px;
            display: block;
            width: $size;
            height: $size;
            transition: all 0.3s ease-in;
            position: relative;
            border-radius: 50%;
            border: 1px solid #fff;
        }
        &:hover:before {
            filter: invert(1);
        }
    }
    .b-button-inner {
        display: none;
    }
	&-title {
		@include font(24px, false);
		line-height: 1;
		font-weight: 300;
		text-transform: uppercase;
		text-align: center;
	}
}
