.b-pdp-navigation {
	padding: 10px $gutter-global;
	@include font(0, 0);
	text-align: right;
	text-transform: uppercase;
	
	&_item {
		position: relative;
		display: inline-block;
		text-decoration: none;
		@include font(11px);
		line-height: 10px;
		color: $color-breadcrumbs;
		
		&:before {
			float: left;
			@include font(6px, false);
			margin-right: 5px;
		}
		
		&.icon-arrow-right:before {
			float: right;
			margin-left: 5px;
			margin-right: 0;
		}
	}
	
	&_item + &_item {
		margin-left: 12px;
		padding-left: 12px;
		border-left: 1px solid $color-breadcrumbs;
	}
	
	&_image {
		display: none;
		position: absolute;
		z-index: $z-pdp-info + 1;
		top: 100%; left: 50%;
		width: 80px;
		margin: 15px 0 0 -40px;
		padding: 5px 12px;
		border: 1px solid $color-grey_light3;
		background: $color-white;
		
		&:before,
		&:after {
			content: '';
			position: absolute;
			bottom: 100%; left: 50%;
			border-style: solid;
			border-width: 0 7px 11px;
			border-color: $color-grey_light3 transparent;
			margin-left: -7px;
		}
		
		&:after {
			border-width: 0 6px 9px;
			border-color: $color-white transparent;
			margin-left: -6px;
		}
		
		img {
			width: 100%;
		}
	}
	
	&_item:hover &_image {
		display: block;
	}
}
