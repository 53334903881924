// Product listing page

.b-search_results {
  &-product_list {
    margin-top: 20px;
    .loader {
      position: fixed;
    }
  }
}

.pt_product-search-result {
  @include plp-carousel;
}
