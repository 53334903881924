@include dialog;

.ui-dialog {
	max-width: 630px;
	padding: 20px 20px 0;

	&-content {
		padding: 0 0 20px;

		+ .ui-dialog-buttonpane {
			margin: -20px 0 20px;
		}
	}

	&-titlebar-close {
		border: $color-none;
	}

	&.dialog-video {
		max-width: 1280px;
	}

	.js-loading:before,
	.loader {
		background-color: fade-out($color-white, 0.25);
	}
}

.desktop .ui-dialog {
    background-color: #fff;
}

%dialog-title,
.ui-dialog-title {
	@extend %heading-2;
	margin: 0 25px 20px;
	text-align: center;
}

.ui-icon-closethick {
	@include icon(close);
}
