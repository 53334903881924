@mixin checkout-confirmation {
	.b-confirmation {
		max-width: 290px;
		margin: 0 auto;
		line-height: 24px;
		text-align: center;

		&-wrapper {
			margin-top: 100px;

			@include respond-to(not-desktop) {
				margin-top: 50px;
			}
		}

		&-message {
			margin-bottom: 15px;
		}

		&-actions {
			margin-bottom: 25px;
			text-align: center;
		}

		&-number {
			.label {
				display: none;
			}
			
			.value:before {
				content: '#';
			}
		}

		p {
			margin-bottom: 0.5em;
		}

		&-register {
			margin: 40px auto;
			text-align: center;

			@include respond-to(phone) {
				margin: 20px auto;
			}

			.f-create_account {
				margin-top: 20px;
				text-align: left;
			}

			.form-row-button {
				margin-bottom: 0;
				text-align: center;
			}
		}
		
		&-coupon_code {
			max-width: 740px;
			margin: 40px auto;
			text-align: center;
		}
		
		&-coupon_value {
			display: inline-block;
			min-width: 290px;
			max-width: 100%;
			margin: 25px auto;
			padding: 10px 20px;
			border: 2px solid $color-red;
			background-color: $color-white;
			font-weight: 700;
			line-height: 1;
			color: $color-red;
			text-transform: uppercase;
		}
		
		&-coupon_note {
			@include font(11px, 0.5px);
			line-height: 1;
			color: $color-black;
		}
	}
}