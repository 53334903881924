.b-store_avilability {

	&-title {
		@extend %dialog-title;
	}

	&-product {
		margin-bottom: 20px;
	}

	.b-pdp-image_wrapper {
		float: left;
		width: 25%;
		margin: 0 5% 0 0;
		padding: 0;

		@include respond-to(phone) {
			display: none;
		}
	}

	.b-pdp-variations {
		@include respond-to(not-phone) {
			display: inline-block;
			vertical-align: top;
			width: 70%;
		}
	}

	.attribute-size .b-swatches-list {
		max-height: 135px;
	}

	.b-store-link {
		display: none;
	}

	&-product_name {
		margin-bottom: 0;
	}

	&-product_number {
		margin-bottom: 10px;
		@include font($font_size-small, false);
		color: $color-grey2;
		text-transform: uppercase;
	}

	&-product_price {
		overflow: hidden;
		margin: 0 0 20px;
		padding: 0;
		border: 0;
	}

	&-search_form {
		@include respond-to(not-phone) {
			@include flexbox;
			@include ai-end;


			.f-field-select {
				@include flexbox;
				@include jc-center;
				padding: 10px 0;
				background: $color-grey_light3;
				width: 100%;
			}
			.f-label {
				margin-right: 10px;
			}
			.f-label-value {
				line-height: 35px;
			}


		}
	}

	&-no-results {
		text-align: center;
	}

	&-no-results-text {
		font-weight: 700;
	}

	&-no-results-button {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	&-no-results-guaranteed {
		font-size: 11px;
		font-weight: 300;
	}

	&-search_result {
		margin-top: 20px;
	}

	&-search_map {
		width: 100%;
		height: 300px;

		@include respond-to(not-phone) {
			float: right;
			width: 50%;
			height: 400px;
		}
	}

	&-stores {
		@include respond-to(not-phone) {
			@include flexbox;
			@include flex-column;
			float: left;
			width: 50%;
			height: 400px;
			padding-right: 20px;

			.b-store_result-list {
				@include flex(1);
			}
		}

		@include respond-to(phone) {
			margin-bottom: 20px;
		}
	}
}

.b-store_result {
	&-title {
		@extend %heading-3;
	}

	&-list {
		@include respond-to(not-phone) {
			overflow: auto;
		}
	}

	&-item {
		padding: 10px;
		margin-bottom: 10px;
		/*background-color: $color-grey_light4;*/
		cursor: pointer;
		border-bottom: 1px solid #000;

		&:last-child {
			margin-bottom: 0;
		}

		&.selected {
			background-color: darken($color-grey_light4, 5);
		}
	}
}

.b-store {
	overflow: hidden;
	@include font($font_size-small, false);
	line-height: 1.2;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	justify-content: space-between;

	&-image {
		float: left;
		width: 30%;
		max-width: 100px;
		margin-right: 15px;
		overflow: hidden;
		flex: 1;

		> img {
			width: 100%;
		}
	}

	&-details {
		overflow: hidden;
		flex: 2;
	}

	&-name,
	&-address {
		margin-bottom: 5px;
	}

	&-address {
		margin: 0 5px 0 0;

		a[href*="tel"],
		a[href*="mailto"] {
			text-decoration: none;
		}
	}

	&-name {
		@include font($font_size, false);
		font-weight: 600;
	}

	&-link {
		margin-top: 10px;
		font-size: 14px;
		font-weight: bold;
		background-color: transparent;
	}

	&-availability {
		flex: 1;
		text-align: right;
		align-self: flex-end;

		&:before {
			content: '';
			height: 10px;
			width: 10px;
			display: inline-block;
			border-radius: 50%;
		}

		&.in-stock-msg:before {
			background-color: $color-green;
		}

		&.preorder-msg:before {
			background-color: $color-orange;
		}

		&.backorder-msg:before {
			background-color: $color-orange;
		}

		&.not-available-msg:before,
		&.last-left:before {
			background-color: $color-red;
		}
	}

	&-driving-direction {
		margin-top: 7px;
		display: inline-block;
	}
	&-booking_container {
		margin: 20px 0;
		p {
			color: $color-grey2;
			font-size: 12px;
		}
	}
	&-booking_button {
		white-space: nowrap;
	}
	
}

.dialog-store_availability {
	max-width: 680px;
}

.pt_product-details {
	.b-store_availability_button {
		.b-store_availability_button-button {
			width: 100%;
			padding: 15px 40px;
			text-align: center;
			border: 1px solid #999;
		}
	}
}

.b-store_availability_button {
	float: right;
	width: 100%;
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;

	@media screen and (max-width: 1200px) {
		float: left;
	}

	&-button {
		&.m-text {
			text-decoration: none;
			&:hover {
				background-color: #999;
				& .b-button-inner,
				&:before {
					color: #fff;
				}
			}
		}
		.b-button-inner {
			text-transform: uppercase;
		}
	}
}

.b-store_avilability-search_map {
	.gmnoprint.gm-bundled-control {
		bottom: 55px !important;
		right: 20px !important;

		& div:not(.gmnoprint) {
			width: 20px !important;
			height: 41px !important;

			& > button {
				width: 20px !important;
				height: 20px !important;

				& > img {
					height: 12px !important;
					width: 12px !important;
				}
			}

			& > button + div {
				width: 15px !important;
				height: 1px !important;
			}
		}
	}
}
