@include checkout-confirmation;

.b-confirmation {
	&-title {
		@extend %heading-1;
	}

	&-number {
		@extend %heading-3;
	}

	&-coupon_title {
		@extend %heading-3;
		color: $color-black;
		text-transform: none;
	}

	&-coupon_code {
		padding: 20px 80px;
		background-color: $color-white;

		@include respond-to(phone) {
			padding: 20px;
		}
	}
}
